import { cn } from "@/lib/utils";
import { GoSync } from "react-icons/go";

/**
 * Renders a loader component.
 *
 * @param {string} className - The optional CSS class name for the loader component.
 * @returns {JSX.Element} The loader component.
 *
 * @example
 * ```tsx
 * const App: React.FC = (loading) => {
 *  return loading ? <Loader /> : <p>Hello world</p>
 * };
 * ```
 */
const Loader = ({ className }: { className?: string }): JSX.Element => (
	<GoSync className={cn({ inputs: ["inline m-1 size-5 animate-spin direction-reverse", className] })} />
);

export default Loader;
