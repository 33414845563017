import Loader from "@/components/Loader";
import { Button, buttonVariants } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { postApiUserAuthForgotPasswordMutationRequestSchema, usePostApiUserAuthForgotPasswordHook } from "api/gen";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import type { z } from "zod";

export const Route = createFileRoute("/forgotPassword")({
	component: () => ForgotPassword(),
	beforeLoad(opts) {
		if (opts.context.auth.token) {
			return { redirect: "/webpanel/veichles" };
		}
	},
});

const ForgotPassword = () => {
	const { mutate: forgotPassword, isPending: loading } = usePostApiUserAuthForgotPasswordHook();

	function onSubmit(data: z.infer<typeof postApiUserAuthForgotPasswordMutationRequestSchema>) {
		forgotPassword(data, {
			onSuccess() {
				toast.success(
					"Abbiamo inviato la nuova password al tuo indirizzo email. Ricorda di controllare anche la casella della posta indesiderata.",
				);
			},
			onError(error, variables, context) {
				let errorMessage = "Richiesta non valida, contatta il servizio clienti";
				if (error instanceof Error && error.message !== "") {
					errorMessage = error.message;
				} else if (error instanceof Error && error.message !== "") {
					errorMessage = error.message;
				}
				console.error("🚀 ~ errorMessage:", errorMessage);
				toast.error(errorMessage);
			},
		});
	}

	const form = useForm<z.infer<typeof postApiUserAuthForgotPasswordMutationRequestSchema>>({
		mode: "all",
		resolver: zodResolver(postApiUserAuthForgotPasswordMutationRequestSchema),
		defaultValues: {
			email: "",
		},
	});

	return (
		<>
			<div className="container h-screen flex flex-row items-center justify-center max-w-full bg-background-content ">
				<div className="drop-shadow-md absolute right-4 top-4 md:right-8 md:top-8">
					<Link
						to="/signIn"
						className={cn({ inputs: [buttonVariants({ variant: "default" })] })}
					>
						Accedi
					</Link>
				</div>
				<div className="p-8 mx-auto container items-center justify-center drop-shadow-md">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] rounded-2xl shadow-2xl py-14 px-8  bg-background-base">
						<div className="flex flex-col space-y-2 text-center">
							<h1 className="text-2xl font-semibold tracking-tight ">Password dimenticata</h1>
							<p className="text-sm py-5">Per resettare la tua password dobbiamo prima inviarti un&apos;email. </p>
							<Form {...form}>
								<form
									id="my-form"
									onSubmit={form.handleSubmit(onSubmit)}
									className="w-full space-y-6"
								>
									<FormField
										control={form.control}
										name="email"
										render={({ field }) => (
											<FormItem className={cn({ inputs: ["w-full"] })}>
												<FormControl>
													<Input
														className="w-full"
														placeholder="Inserisci indirizzo email"
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<Button
										type="submit"
										disabled={loading}
										variant={"default"}
										className="items-center place-content-center justify-center w-full"
									>
										{loading && <Loader />} Continua
									</Button>
								</form>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
