import * as React from "react";
import {
	Controller,
	type ControllerProps,
	type FieldPath,
	type FieldValues,
	FormProvider,
	useFormContext,
} from "react-hook-form";

import { Label, type Root } from "@/components/ui/label";
import { cn } from "@/lib/utils";

interface SlotProps extends React.HTMLAttributes<HTMLElement> {
	children?: React.ReactNode;
}
/**
 * Slot component represents a customizable slot in a form.
 * It forwards the ref to the underlying HTML element.
 */
const Slot: React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>> = React.forwardRef<
	HTMLElement,
	SlotProps
>(({ className, ...props }, ref) => {
	return (
		<div
			ref={ref as React.Ref<HTMLDivElement>}
			className="w-full"
			{...props}
		/>
	);
});

/**
 * A component that provides a form.
 *
 * @external https://ui.shadcn.com/docs/components/form
 * @example ESEMPIO DI UTILIZZO IN UN COMPONENTE
 * ```tsx
 * export function ExampleForm({ row }: { row: Row<GenericType> }) {
 * 	const { rideId } = useRideIdStore();
 * 	const { nccTeamId } = useNccTeamIdStore();
 * 	const { mutate: transportTierPriceVariantEdit } = usePostApiTransportTierPriceVariantEditHook(undefined, {
 * 		mutation: { mutationKey: [{ rideTier_id: rideId }] },
 * 	});
 * 	const [isSwitchOn, setIsSwitchOn] = useState<boolean | undefined>(row.original.public);
 * 	const [extraKmPrice, setExtraKmPrice] = useState<string | undefined>();
 * 	const [name, setName] = useState<string | undefined>();
 * 	const [description, setDescription] = useState<string | undefined>();
 * 	const [extraKmPriceReason, setExtraKmPriceReason] = useState<string | undefined>();
 *
 *
 * 	const form = useForm<z.infer<typeof FormSchema>>({
 * 		mode: "all",
 * 		resolver: zodResolver(FormSchema),
 * 		defaultValues: {
 * 			id: row.original.id,
 * 			name: row.original.name,
 * 			description: row.original.description,
 * 			extra_km_price_reason: row.original.extra_km_price_reason,
 * 			rules: row.original.rules,
 * 			public: row.original.public,
 * 			extra_km_price: row.original.extra_km_price,
 * 			ncc_team_id: nccTeamId,
 * 		},
 * 	});
 *
 * 	function onSubmit(data: z.infer<typeof FormSchema>) {
 * 		data.id = row.original.id;
 * 		data.public = isSwitchOn ?? row.original.public;
 * 		data.extra_km_price = extraKmPrice?.replace(",", ".") ?? row.original.extra_km_price;
 * 		data.extra_km_price_reason = extraKmPriceReason ?? row.original.extra_km_price_reason;
 * 		data.name = name ?? row.original.name;
 * 		data.description = description ?? row.original.description;
 * 		transportTierPriceVariantEdit(data, {
 * 			onSuccess() {
 * 				queryClient.invalidateQueries({ queryKey: ["additionalFee"] });
 * 				setIsDialogOpenEditDistanceEdit(false);
 * 				toast.success("Tariffa modificata con successo");
 * 			},
 * 			onError() {
 * 				toast.error("Errore durante la modifica della tariffa");
 * 				setIsDialogOpenEditDistanceEdit(false);
 * 			},
 * 		});
 * 	}
 *
 * 	const handleExtraKmPriceChange: CurrencyInputProps["onValueChange"] = value => setExtraKmPrice(value);
 *
 * 	return (
 * 				<Form {...form}>
 * 					<form
 * 						className="w-full space-y-6"
 * 						onSubmit={form.handleSubmit(onSubmit)}
 * 					>
 * 						<div className="grid sm:grid-cols-2  gap-4 content-center">
 * 							<FormField
 * 								control={form.control}
 * 								name="name"
 * 								render={() => (
 * 									<FormItem>
 * 										<FormLabel>Nome</FormLabel>
 * 										<FormControl>
 * 											<Input
 * 												placeholder="inserisci il nome"
 * 												defaultValue={row.original.name}
 * 												value={name}
 * 												onChange={e => setName(e.target.value ?? row.original.name)}
 * 											/>
 * 										</FormControl>
 * 										<FormMessage />
 * 									</FormItem>
 * 								)}
 * 							/>
 * 							<FormField
 * 								control={form.control}
 * 								name="description"
 * 								render={() => (
 * 									<FormItem>
 * 										<FormLabel>Descrizione</FormLabel>
 * 										<FormControl>
 * 											<Input
 * 												type="text"
 * 												placeholder="inserisci la descrizione"
 * 												defaultValue={row.original.description}
 * 												value={description}
 * 												onChange={e => setDescription(e.target.value)}
 * 											/>
 * 										</FormControl>
 * 										<FormMessage />
 * 									</FormItem>
 * 								)}
 * 							/>
 * 							<FormField
 * 								control={form.control}
 * 								name="extra_km_price_reason"
 * 								render={() => (
 * 									<FormItem>
 * 										<FormLabel>Ragione dell'extra prezzo / km</FormLabel>
 * 										<FormControl>
 * 											<Input
 * 												type="text"
 * 												placeholder="inserisci la ragione"
 * 												defaultValue={row.original.extra_km_price_reason}
 * 												value={extraKmPriceReason}
 * 												onChange={e => setExtraKmPriceReason(e.target.value ?? row.original.extra_km_price_reason)}
 * 											/>
 * 										</FormControl>
 * 										<FormMessage />
 * 									</FormItem>
 * 								)}
 * 							/>
 * 							<FormField
 * 								control={form.control}
 * 								name="extra_km_price"
 * 								render={() => (
 * 									<FormItem>
 * 										<FormLabel>Extra prezzo / km</FormLabel>
 * 										<FormControl>
 * 											<CurrencyInput
 * 												customInput={Input}
 * 												placeholder="inserisci il prezzo"
 * 												type="string"
 * 												defaultValue={row.original.extra_km_price}
 * 												decimalsLimit={2}
 * 												value={extraKmPrice}
 * 												onValueChange={handleExtraKmPriceChange}
 * 											/>
 * 										</FormControl>
 * 										<FormMessage />
 * 									</FormItem>
 * 								)}
 * 							/>
 * 							<FormField
 * 								control={form.control}
 * 								name="public"
 * 								render={() => (
 * 									<FormItem>
 * 										<FormLabel>Regola attiva</FormLabel>
 * 										<FormControl>
 * 											<Switch
 * 												checked={isSwitchOn}
 * 												// defaultChecked={row.original.public}
 * 												onChange={checked => {
 * 													setIsSwitchOn(checked); // This will toggle the state, moving the thumb to the opposite side
 * 												}}
 * 											/>
 * 										</FormControl>
 * 										<FormMessage />
 * 									</FormItem>
 * 								)}
 * 							/>
 * 						</div>
 * 						<div className="flex justify-center items-center space-x-2">
 * 							<Button
 * 								type="submit"
 * 								size={"xl"}
 * 							>
 * 								Modifica
 * 							</Button>
 * 							<Button
 * 								type="button"
 * 								size={"xl"}
 * 								variant={"outline"}
 * 								onClick={() => setIsDialogOpenEditDistanceEdit(false)}
 * 							>
 * 								Annulla
 * 							</Button>
 * 						</div>
 * 					</form>
 * 				</Form>
 * 		);
 * }
 * ```
 */
const Form = FormProvider;

/**
 * Represents the context value for a form field.
 *
 * @template TFieldValues The type of the form field values.
 * @template TName The type of the form field name.
 */
type FormFieldContextValue<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	/**
	 * The name of the form field.
	 */
	name: TName;
};

/**
 * Context for form field components.
 */
const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

/**
 * Renders a form field component.
 *
 * @template TFieldValues - The type of form field values.
 * @template TName - The type of form field name.
 * @param {ControllerProps<TFieldValues, TName>} props - The props for the form field component.
 * @returns {JSX.Element} - The rendered form field component.
 */
const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	...props
}: ControllerProps<TFieldValues, TName>): JSX.Element => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	);
};

/**
 * Custom hook that provides form field related data.
 * @returns {Object} The form field data.
 * @throws {Error} If used outside of a FormField component.
 */
const useFormField = () => {
	const fieldContext = React.useContext(FormFieldContext);
	const itemContext = React.useContext(FormItemContext);
	const { getFieldState, formState } = useFormContext();

	const fieldState = getFieldState(fieldContext.name, formState);

	if (!fieldContext) {
		throw new Error("useFormField should be used within <FormField>");
	}

	const { id } = itemContext;

	return {
		id,
		name: fieldContext.name,
		formItemId: `${id}-form-item`,
		formDescriptionId: `${id}-form-item-description`,
		formMessageId: `${id}-form-item-message`,
		...fieldState,
	};
};

type FormItemContextValue = {
	id: string;
};

/**
 * Context for the form item component.
 */
const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

/**
 * A form item component that wraps its children in a div element.
 * @param {object} props - The props for the FormItem component.
 * @param {string} props.className - The CSS class name for the div element.
 * @param {React.Ref<HTMLDivElement>} ref - The ref for the div element.
 * @returns {JSX.Element} The rendered FormItem component.
 */
const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		// Generate a unique id for the form item
		const id = React.useId();

		return (
			<FormItemContext.Provider value={{ id }}>
				<div
					ref={ref}
					className={cn({ inputs: ["space-y-2", className] })}
					{...props}
				/>
			</FormItemContext.Provider>
		);
	},
);
FormItem.displayName = "FormItem";

/**
 * FormLabel component is a wrapper around the Label component that handles form field related logic.
 * It displays a label for a form field and applies appropriate styling based on the error state.
 *
 * @param {object} props - The component props
 * @param {string} props.className - The class name for the component
 * @param {React.Ref} ref - The ref object for the component
 * @returns {JSX.Element} - The rendered JSX element
 */
const FormLabel = React.forwardRef<React.ElementRef<typeof Root>, React.ComponentPropsWithoutRef<typeof Root>>(
	({ className, ...props }, ref) => {
		// Retrieve error and formItemId from useFormField hook
		const { error, formItemId } = useFormField();
		return (
			<Label
				ref={ref}
				className={cn({ inputs: [error && "text-red-500", className] })}
				htmlFor={formItemId}
				{...props}
			/>
		);
	},
);
FormLabel.displayName = "FormLabel";

/**
 * A form control component that wraps a Slot component.
 *
 * @component
 * @param {React.ComponentPropsWithoutRef<typeof Slot>} props - The props for the Slot component.
 * @param {React.Ref<React.ElementRef<typeof Slot>>} ref - The ref for the Slot component.
 * @returns {JSX.Element} The rendered form control component.
 */
const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
	({ ...props }, ref) => {
		// Retrieve form field related data using the useFormField hook
		const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

		return (
			<Slot
				ref={ref}
				id={formItemId}
				aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
				aria-invalid={!!error}
				{...props}
			/>
		);
	},
);
FormControl.displayName = "FormControl";

/**
 * Represents a form description component.
 * This component renders a paragraph element with a form description.
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {React.Ref<HTMLParagraphElement>} ref - The ref for the paragraph element.
 * @returns {JSX.Element} The rendered form description component.
 */
const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => {
		// Get the form description ID from the useFormField hook
		const { formDescriptionId } = useFormField();

		return (
			<p
				ref={ref}
				id={formDescriptionId}
				className={cn({ inputs: ["text-sm text-foreground-muted", className] })}
				{...props}
			/>
		);
	},
);
FormDescription.displayName = "FormDescription";

/**
 * A component that displays a message related to a form field.
 * It renders a paragraph element with an optional error message or custom content.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLParagraphElement>} props - The component props.
 * @param {string} props.className - The class name for the paragraph element.
 * @param {React.ReactNode} props.children - The custom content to be rendered inside the paragraph element.
 * @returns {JSX.Element} The rendered FormMessage component.
 */
const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, children, ...props }, ref) => {
		const { error, formMessageId } = useFormField();
		const body = error ? String(error?.message) : children;

		if (!body) {
			return null;
		}

		return (
			<p
				ref={ref}
				id={formMessageId}
				className={cn({ inputs: ["text-sm text-red-500 font-medium ", className] })}
				{...props}
			>
				{body}
			</p>
		);
	},
);
FormMessage.displayName = "FormMessage";

export { Form, FormItem, FormLabel, FormControl, FormMessage, FormField };
