import type * as React from "react";
import { DayPicker } from "react-day-picker";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

type CalendarProps = React.ComponentProps<typeof DayPicker>;

/**
 * Renders a calendar component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the calendar component.
 * @param {Object} props.classNames - The CSS class names for different parts of the calendar component.
 * @param {boolean} props.showOutsideDays - Determines whether to show days outside the current month.
 * @param {Object} props... - Additional props to be passed to the underlying DayPicker component.
 * @returns {JSX.Element} The rendered calendar component.
 * 
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  return (
 * <Dropdown
									clickOnClose={false}
									trigger={
										<FormControl>
											<Button
												type="button"
												variant={"default"}
												className={cn({
													inputs: ["w-full flex justify-between", !field.value && "text-muted-foreground"],
												})}
											>
												{registerDate ? formatDate(registerDate) : <span>Seleziona una data</span>}
												<GoCalendar />
											</Button>
										</FormControl>
									}
									{...field}
								>
									<Calendar
										mode="single"
										selected={registerDate}
										className="bg-transparent rounded-none"
										onSelect={e => {
											setRegisterDate(e);
										}}
										disabled={date => date > new Date() || date < new Date("1900-01-01")}
										initialFocus
									/>
								</Dropdown>
 *  )
* };
 * ```
 */
function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps): JSX.Element {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn({ inputs: ["p-3 bg-background-base", className] })}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
				month: "space-y-4",
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium",
				nav: "space-x-1 flex items-center",
				nav_button: cn({
					inputs: [
						buttonVariants({ variant: "transparent" }),
						"h-7 w-7 bg-foreground-muted p-0 opacity-50 hover:opacity-100",
					],
				}),
				nav_button_previous: "absolute left-1",
				nav_button_next: "absolute right-1",
				table: "w-full border-collapse space-y-1",
				head_row: "flex",
				head_cell: "text-foreground rounded-control w-9 font-normal text-[0.8rem]",
				row: "flex w-full mt-2",
				cell: "h-9 w-9 text-center text-sm rounded-control p-0 relative [&:has([aria-selected])]:bg-accent focus-within:relative focus-within:z-20",
				day: cn({ inputs: [buttonVariants({ variant: "ghost" }), "h-9 w-9 p-0 font-normal"] }),
				day_range_end: "day-range-end",
				day_selected: "bg-accent hover:bg-muted focus:bg-accent",
				day_today: "bg-accent text-accent-foreground",
				day_outside:
					"day-outside text-muted-foreground text-foreground-muted aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
				day_disabled: "text-muted-foreground text-foreground-muted",
				day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: ({ ...props }) => (
					<GoChevronLeft
						className="h-4 w-4"
						{...props}
					/>
				),
				IconRight: ({ ...props }) => (
					<GoChevronRight
						className="h-4 w-4"
						{...props}
					/>
				),
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
