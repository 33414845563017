import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Switch from "@/components/ui/switch";
import { useNccTeamIdStore, useRideIdStore } from "@/hooks/store/main";
import { createZodFromType } from "@/lib/utils";
import { queryClient } from "@/main";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Row } from "@tanstack/react-table";
import {
	type PostApiTransportTierPriceVariantEditMutationRequest,
	usePostApiTransportTierPriceVariantEditHook,
} from "api/gen";
import { useEffect, useState } from "react";
import CurrencyInput, { type CurrencyInputProps } from "react-currency-input-field";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiEdit2 as Edit } from "react-icons/fi";
import { z } from "zod";
import type { IDistanceData } from "./_types";

const FormSchema = createZodFromType<PostApiTransportTierPriceVariantEditMutationRequest>({
	id: z.number(),
	name: z.string(),
	description: z.string(),
	rules: z.string().default(""),
	public: z.boolean(),
	extra_km_price: z.string(),
	extra_km_price_reason: z.string(),
	ncc_team_id: z.number(),
});

export function DistanceEditDialog({ row }: { row: Row<IDistanceData> }) {
	const [isDialogOpenEditDistanceEdit, setIsDialogOpenEditDistanceEdit] = useState(false);
	const { rideId } = useRideIdStore();
	const { nccTeamId } = useNccTeamIdStore();
	const { mutate: transportTierPriceVariantEdit } = usePostApiTransportTierPriceVariantEditHook(undefined, {
		mutation: { mutationKey: [{ rideTier_id: rideId }] },
	});
	const [isSwitchOn, setIsSwitchOn] = useState<boolean | undefined>(row.original.public);
	const [extraKmPrice, setExtraKmPrice] = useState<string | undefined>();
	const [name, setName] = useState<string | undefined>();
	const [description, setDescription] = useState<string | undefined>();
	const [extraKmPriceReason, setExtraKmPriceReason] = useState<string | undefined>();

	useEffect(() => {
		if (isDialogOpenEditDistanceEdit) setIsSwitchOn(row.original.public);
	}, [isDialogOpenEditDistanceEdit]);

	const form = useForm<z.infer<typeof FormSchema>>({
		mode: "all",
		resolver: zodResolver(FormSchema),
		defaultValues: {
			id: row.original.id,
			name: row.original.name,
			description: row.original.description,
			extra_km_price_reason: row.original.extra_km_price_reason,
			rules: row.original.rules,
			public: row.original.public,
			extra_km_price: row.original.extra_km_price,
			ncc_team_id: nccTeamId,
		},
	});

	function onSubmit(data: z.infer<typeof FormSchema>) {
		data.id = row.original.id;
		data.public = isSwitchOn ?? row.original.public;
		data.extra_km_price = extraKmPrice?.replace(",", ".") ?? row.original.extra_km_price;
		data.extra_km_price_reason = extraKmPriceReason ?? row.original.extra_km_price_reason;
		data.name = name ?? row.original.name;
		data.description = description ?? row.original.description;
		transportTierPriceVariantEdit(data, {
			onSuccess() {
				queryClient.invalidateQueries({ queryKey: ["additionalFee"] });
				setIsDialogOpenEditDistanceEdit(false);
				toast.success("Tariffa modificata con successo");
			},
			onError() {
				toast.error("Errore durante la modifica della tariffa");
				setIsDialogOpenEditDistanceEdit(false);
			},
		});
	}

	const handleExtraKmPriceChange: CurrencyInputProps["onValueChange"] = value => setExtraKmPrice(value);

	return (
		<>
			<Button
				onClick={() => setIsDialogOpenEditDistanceEdit(true)}
				variant={"ghost"}
				className="px-4 py-2 rounded-md"
			>
				<Edit />
			</Button>
			<Dialog
				isOpen={isDialogOpenEditDistanceEdit}
				onClose={() => setIsDialogOpenEditDistanceEdit(false)}
				title="Modifica la distanza"
			>
				<Form {...form}>
					<form
						className="w-full space-y-6"
						onSubmit={form.handleSubmit(onSubmit)}
					>
						<div className="grid sm:grid-cols-2  gap-4 content-center">
							<FormField
								control={form.control}
								name="name"
								render={() => (
									<FormItem>
										<FormLabel>Nome</FormLabel>
										<FormControl>
											<Input
												placeholder="inserisci il nome"
												defaultValue={row.original.name}
												value={name}
												onChange={e => setName(e.target.value ?? row.original.name)}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="description"
								render={() => (
									<FormItem>
										<FormLabel>Descrizione</FormLabel>
										<FormControl>
											<Input
												type="text"
												placeholder="inserisci la descrizione"
												defaultValue={row.original.description}
												value={description}
												onChange={e => setDescription(e.target.value)}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="extra_km_price_reason"
								render={() => (
									<FormItem>
										<FormLabel>Ragione dell'extra prezzo / km</FormLabel>
										<FormControl>
											<Input
												type="text"
												placeholder="inserisci la ragione"
												defaultValue={row.original.extra_km_price_reason}
												value={extraKmPriceReason}
												onChange={e => setExtraKmPriceReason(e.target.value ?? row.original.extra_km_price_reason)}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="extra_km_price"
								render={() => (
									<FormItem>
										<FormLabel>Extra prezzo / km</FormLabel>
										<FormControl>
											<CurrencyInput
												customInput={Input}
												placeholder="inserisci il prezzo"
												type="string"
												defaultValue={row.original.extra_km_price}
												decimalsLimit={2}
												value={extraKmPrice}
												onValueChange={handleExtraKmPriceChange}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="public"
								render={() => (
									<FormItem>
										<FormLabel>Regola attiva</FormLabel>
										<FormControl>
											<Switch
												checked={isSwitchOn}
												// defaultChecked={row.original.public}
												onChange={checked => {
													setIsSwitchOn(checked); // This will toggle the state, moving the thumb to the opposite side
												}}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex justify-center items-center space-x-2">
							<Button
								type="submit"
								size={"xl"}
							>
								Modifica
							</Button>
							<Button
								type="button"
								size={"xl"}
								variant={"outline"}
								onClick={() => setIsDialogOpenEditDistanceEdit(false)}
							>
								Annulla
							</Button>
						</div>
					</form>
				</Form>
			</Dialog>
		</>
	);
}
