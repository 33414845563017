import Page from "@/components/navigation/page";

import { DataTablePagination } from "@/components/data-pagination";
import { DataTable } from "@/components/data-table";
import { Skeleton } from "@/components/ui/skeleton";
import { ErrorRoutingComponent } from "@/root";
import type { GetMeData, IPagination } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useRouterState } from "@tanstack/react-router";
import {
	type ColumnDef,
	type ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import {
	getApiNccTeamNccLocationListNccTeamIdQueryOptions,
	getApiUserMeQueryOptions,
	useGetApiNccTeamNccLocationListNccTeamIdHookSuspense,
} from "api/gen";
import { useMemo, useState } from "react";
import { GarageUtils } from "./_GarageUtils";
import { columnsGarage } from "./_columns";
import { type Garages, GaragesearchSchema, type ResGarages } from "./_types";

export const Route = createFileRoute("/webpanel/garage/")({
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	validateSearch: search => GaragesearchSchema.parse(search),
	loaderDeps: ({ search: { page } }) => {
		return { page };
	},
	loader: ({ context: { queryClient }, deps: { page } }) => {
		queryClient
			.ensureQueryData(getApiUserMeQueryOptions())
			.then(getMeCache =>
				queryClient.ensureQueryData(
					getApiNccTeamNccLocationListNccTeamIdQueryOptions(
						(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
						undefined,
						{ params: { page } },
					),
				),
			);
	},
	component: ManagementGarages,
});

function ManagementGarages() {
	const currentUrlLocation = useRouterState();

	const { data } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeCache = data as GetMeData | undefined;
	const { data: GaragesQuery, isPending } = useGetApiNccTeamNccLocationListNccTeamIdHookSuspense(
		(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
		undefined,
		{
			query: {
				queryKey: [
					`/api/ncc-team/ncc-location/list/${
						(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
					}?page=${currentUrlLocation.location.search.page}`,
					currentUrlLocation.location.search.page,
				],
			},
			client: { params: { page: currentUrlLocation.location.search.page } },
		},
	);
	const garages = GaragesQuery as (ResGarages | undefined) & IPagination;

	const tableData = useMemo<Garages[]>(
		() => (isPending ? Array(30).fill({}) : garages?.data ?? []),
		[isPending, garages?.data],
	);

	const tableColumns = useMemo<ColumnDef<Garages>[]>(
		() =>
			isPending
				? columnsGarage.map(column => ({
						...column,
						cell: () => <Skeleton className="h-2 w-full" />,
					}))
				: columnsGarage,
		[isPending, columnsGarage],
	);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		columns: tableColumns,
		data: tableData,
		getCoreRowModel: getCoreRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
		},
	});

	return (
		<Page>
			<GarageUtils table={table} />
			<DataTable
				columns={tableColumns}
				table={table}
				dataTable={tableData}
			/>
			<DataTablePagination
				isPendingPaginationData={isPending}
				dataPagination={garages}
			/>
		</Page>
	);
}
