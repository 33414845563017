import { type Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
	FloatingFocusManager,
	FloatingOverlay,
	FloatingPortal,
	type Placement,
	autoUpdate,
	offset,
	size,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
	useTransitionStyles,
} from "@floating-ui/react";
import { type ReactElement, type ReactNode, useState } from "react";
/**
 * Dropdown component that displays a trigger button and a content area that can be toggled open or closed.
 *
 * @param {ReactElement<(typeof Button)["$$typeof"]>} trigger - The trigger button element.
 * @param {ReactNode} children - The content to be displayed when the dropdown is open.
 * @param {string} className - Additional CSS class names for the dropdown container.
 * @param {boolean} backgroundBlur - Whether to apply a backdrop blur effect when the dropdown is open.
 * @param {Placement} placement - The placement of the dropdown content.
 * @param {boolean} clickOnClose - Closes the dropdown when clicking on it. Default true.
 * @returns {JSX.Element} The rendered dropdown component.
 * 
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  return (
		<Dropdown
				backgroundBlur={true}
				trigger={
					<div
						className={cn({
							inputs: [
								buttonVariants({
									variant: "ghost",
								}),
								"gap-4",
							],
						})}
					>
						<GoChevronDown size={"24px"} />
					</div>
				}
			>
				<Link
					to="/webpanel/settings"
					className={cn({ inputs: [buttonVariants({ variant: "transparent", align: "left" })] })}
				>
					<GoGear className="mt-1" /> Impostazioni
				</Link>
		</Dropdown>
 *  )
* };
 * ```
*/
const Dropdown = (props: {
	trigger: ReactElement<typeof Button>;
	children?: ReactNode;
	className?: string;
	backgroundBlur?: boolean;
	placement?: Placement;
	clickOnClose?: boolean;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const { refs, floatingStyles, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		transform: false,
		middleware: [
			offset(10),
			size({
				apply({ rects, elements, availableHeight }) {
					Object.assign(elements.floating.style, {
						maxHeight: `${availableHeight}px`,
						minWidth: `${rects.reference.width}px`,
					});
				},
				padding: 10,
			}),
		],
		whileElementsMounted: autoUpdate,
		placement: props.placement ?? "bottom",
	});

	const { isMounted, styles } = useTransitionStyles(context, {
		duration: 500,
		initial: ({ side }) => ({
			opacity: 0,
			transform: `scale(0.7) ${side === "top" ? "translateY(12px)" : ""}${
				side === "bottom" ? "translateY(-12px)" : ""
			}`,
			// transform: `scale(0.8) translateY(-10px)`,
		}),
		common: {
			// transitionTimingFunction: "ease-in-out",
			transitionTimingFunction: "cubic-bezier(.3,2,.1,1)",
			zIndex: 100,
			width: "inherit",
		},
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context);

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	return (
		<>
			<div
				ref={refs.setReference}
				{...getReferenceProps()}
				className={cn({
					inputs: [
						"relative w-full",
						props.backgroundBlur && isOpen && "animate-raise",
						props.backgroundBlur && !isOpen && "animate-lower",
						buttonVariants({ variant: "ghost", size: "hidden" }),
					],
				})}
			>
				{props.trigger}
			</div>
			{isMounted && props.backgroundBlur ? (
				<FloatingOverlay
					className={cn({
						inputs: [
							"z-20 opacity-0 transition-all duration-200 backdrop-blur",
							isOpen && "animate-show",
							!isOpen && "animate-hide",
						],
					})}
				/>
			) : null}
			<FloatingPortal>
				{isMounted ? (
					<FloatingFocusManager
						context={context}
						modal={false}
					>
						<div
							ref={refs.setFloating}
							style={{ ...floatingStyles, ...styles }}
							{...getFloatingProps()}
							onClick={() => props.clickOnClose !== false && setIsOpen(false)}
							className={cn({
								inputs: [
									"overflow-y-auto shadow-2xl bg-background-content/90 backdrop-blur rounded shadow-background-base/75 p-2",
									props.className,
								],
							})}
						>
							<div className={cn({ inputs: ["w-full flex flex-col items-stretch overflow-auto", props.className] })}>
								{props.children}
							</div>
						</div>
					</FloatingFocusManager>
				) : null}
			</FloatingPortal>
		</>
	);
};

export default Dropdown;
