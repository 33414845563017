import { useQuery } from "@tanstack/react-query";
import axiosClient from "api/templatesSDK/client";

export function useGetApiTeamSubscriptionsStripeNccTeamIdHook({
	ncc_team_id,
}: {
	ncc_team_id?: number;
}) {
	const queryKey = ["/api/ncc-team/subscriptions/stripe/:nccTeam_id"];

	return useQuery({
		queryFn: async () => {
			const res = await axiosClient({
				method: "get",
				url: `/api/ncc-team/subscriptions/stripe/${ncc_team_id}`,
			});
			return res.data;
		},
		enabled: !!ncc_team_id,
		queryKey,
	});
}
