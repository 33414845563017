import { useMutation } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query";
import type {
	PostApiTransportRiderStatusChange403,
	PostApiTransportRiderStatusChangeHeaderParams,
	PostApiTransportRiderStatusChangeMutationResponse,
} from "api/gen";
import { axiosClient as client } from "api/templatesSDK/client";

type PostApiTransportRiderStatusChangeClient = typeof client<
	PostApiTransportRiderStatusChangeMutationResponse,
	PostApiTransportRiderStatusChange403,
	{ rider_id: number; ncc_team_id: number; available: boolean }
>;
type PostApiTransportRiderStatusChange = {
	data: PostApiTransportRiderStatusChangeMutationResponse;
	error: PostApiTransportRiderStatusChange403;
	request: {
		rider_id: number;
		ncc_team_id: number;
		available: boolean;
	};
	pathParams: never;
	queryParams: never;
	headerParams: PostApiTransportRiderStatusChangeHeaderParams;
	response: PostApiTransportRiderStatusChangeMutationResponse;
	client: {
		parameters: Partial<Parameters<PostApiTransportRiderStatusChangeClient>[0]>;
		return: Awaited<ReturnType<PostApiTransportRiderStatusChangeClient>>;
	};
};
/**
 * @description Changes the calling rider statusAbort operation
 * @summary RiderAvailabilityChangeHook
 * @link /api/transport/rider/status/change
 */
export function usePostApiRiderAvailabilityChangeHook(
	headers?: PostApiTransportRiderStatusChange["headerParams"],
	options: {
		mutation?: UseMutationOptions<
			PostApiTransportRiderStatusChange["response"],
			PostApiTransportRiderStatusChange["error"],
			PostApiTransportRiderStatusChange["request"]
		>;
		client?: PostApiTransportRiderStatusChange["client"]["parameters"];
	} = {},
) {
	const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

	return useMutation({
		mutationFn: async data => {
			const res = await client<
				PostApiTransportRiderStatusChange["data"],
				PostApiTransportRiderStatusChange["error"],
				PostApiTransportRiderStatusChange["request"]
			>({
				method: "post",
				url: "/api/transport/ncc-team/rider/availability",
				data,
				headers: { ...headers, ...clientOptions.headers },
				...clientOptions,
			});
			return res.data;
		},
		...mutationOptions,
	});
}
