import { getAuthCredentials } from "@/hooks/auth/auth-utils";
import { AUTH_CRED } from "@/hooks/auth/constants";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorComponent, RouterProvider, createRouter } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import ReactDOM from "react-dom/client";
import toast from "react-hot-toast";
import "./index.css";
import { resourcesTranslation } from "./i18n.js";
import { routeTree } from "./routeTree.gen";

export const queryCache = new QueryCache({
	onError: error => {
		let errorMessage = "Richiesta non valida, contatta il servizio clienti";
		if (error instanceof AxiosError && error.response?.data.message !== "") {
			errorMessage =
				resourcesTranslation.it.translation[
					error.response?.data.message as keyof typeof resourcesTranslation.it.translation
				] ?? error.response?.data.message;
		} else if (error instanceof AxiosError && error.message !== "") {
			errorMessage = error.message;
		}
		console.error("🚀 ~ errorMessage:", errorMessage);
		toast.error(errorMessage);

		if (
			(error instanceof AxiosError && error.status === 401) ||
			(error instanceof AxiosError && error.response?.status === 401)
		) {
			redirect({ to: "/signIn" });
			sessionStorage.clear();
			localStorage.clear();
			Cookies.remove(AUTH_CRED);
			toast.error("Non hai l'accesso");
		}

		if (
			(error instanceof AxiosError && error.response?.status === 404) ||
			(error instanceof AxiosError && error.status === 404)
		) {
			toast.error("Risultato non trovato");
		}
	},
});

export const mutationCache = new MutationCache({
	onError: error => {
		let errorMessage = "Richiesta non valida, contatta il servizio clienti";
		if (error instanceof AxiosError && error.response?.data.message !== "") {
			errorMessage =
				resourcesTranslation.it.translation[
					error.response?.data.message as keyof typeof resourcesTranslation.it.translation
				] ?? error.response?.data.message;
		} else if (error instanceof AxiosError && error.message !== "") {
			errorMessage = error.message;
		}
		console.error("🚀 ~ error:", errorMessage);
		toast.error(errorMessage);

		if (
			(error instanceof AxiosError && error.status === 401) ||
			(error instanceof AxiosError && error.response?.status === 401)
		) {
			sessionStorage.clear();
			localStorage.clear();
			Cookies.remove(AUTH_CRED);
			redirect({ to: "/signIn" });
			toast.error("Non hai l'accesso", { id: "NoAccessMutation" });
		}

		if (
			(error instanceof AxiosError && error.response?.status === 404) ||
			(error instanceof AxiosError && error.status === 404)
		) {
			toast.error("Risultato non trovato", { id: "NotFoundMutation" });
		}
	},
});

export const queryClient = new QueryClient({
	queryCache,
	mutationCache,
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchOnMount: true,
		},
	},
});

// Set up a Router instance
const router = createRouter({
	routeTree,
	defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
	context: {
		auth: getAuthCredentials(), // We'll inject this when we render
		queryClient,
	},
	defaultPreload: "intent",
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
});

// Register things for typesafety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

const rootElement = document.getElementById("app");

if (rootElement && !rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
		</QueryClientProvider>,
	);
}
