import type { IPagination } from "@/types";
import { z } from "zod";

export const GaragesearchSchema = z.object({
	page: z.number().catch(1),
});

export interface ResGarages extends IPagination {
	data: Garages[];
}

export interface Garages {
	id: number;
	ncc_team_id: number;
	name: string;
	description: string;
	address: string;
	latitude: string;
	longitude: string;
	action_range: string;
	active: boolean;
	available: boolean;
}
