import { DataTablePagination } from "@/components/data-pagination";
import { DataTable } from "@/components/data-table";
import Page from "@/components/navigation/page";
import { Skeleton } from "@/components/ui/skeleton";
import { ErrorRoutingComponent } from "@/root";
import type { GetMeData, IPagination } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useRouterState } from "@tanstack/react-router";
import {
	type ColumnDef,
	type ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { getApiUserMeQueryOptions } from "api/gen";
import { useMemo, useState } from "react";
import { DriversUtils } from "./_DriversUtils";
import { columnsDrivers } from "./_columns";
import { type Drivers, type ResDrivers, driverSearchSchema } from "./_types";
import {
	getApiNccTeamGetNccteamRiderListIdQueryOptions,
	useApiGetNccTeamGetNccteamRiderListIdHook,
} from "./_useGetRiderList";

export const Route = createFileRoute("/webpanel/drivers/management")({
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	validateSearch: search => driverSearchSchema.parse(search),
	loaderDeps: ({ search: { page } }) => {
		return { page };
	},
	loader: ({ context: { queryClient }, deps: { page } }) => {
		queryClient
			.ensureQueryData(getApiUserMeQueryOptions())
			.then(getMeCache =>
				queryClient.ensureQueryData(
					getApiNccTeamGetNccteamRiderListIdQueryOptions(
						(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
						undefined,
						{ params: { page } },
					),
				),
			);
	},
	component: ManagementDrivers,
});

function ManagementDrivers() {
	const currentUrlLocationPageState = useRouterState().location.search.page;

	const { data } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeCache = data as GetMeData | undefined;
	const { data: driversQuery, isPending } = useApiGetNccTeamGetNccteamRiderListIdHook(
		(getMeCache as GetMeData)?.data.nccteams[0]?.id as number,
		{
			query: {
				queryKey: [
					`/api/transport/ncc-team/rider/list/${(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number}`,
					`/api/transport/ncc-team/rider/list/${
						(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
					}?page=${currentUrlLocationPageState}`,
					currentUrlLocationPageState,
				],
			},
			client: {
				params: {
					page: currentUrlLocationPageState,
				},
			},
		},
	);
	const drivers = driversQuery as (ResDrivers | undefined) & IPagination;

	const tableData = useMemo<Drivers[]>(
		() => (isPending ? Array(30).fill({}) : drivers?.data ?? []),
		[isPending, drivers?.data],
	);

	const tableColumns = useMemo<ColumnDef<Drivers>[]>(
		() =>
			isPending
				? columnsDrivers.map(column => ({
						...column,
						cell: () => <Skeleton className="h-2 w-full" />,
					}))
				: columnsDrivers,
		[isPending, columnsDrivers],
	);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		columns: tableColumns,
		data: tableData,
		getCoreRowModel: getCoreRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
		},
	});

	return (
		<Page>
			<DriversUtils
				table={table}
				currentUrlLocationPageState={currentUrlLocationPageState}
				getMeCache={getMeCache}
			/>
			<DataTable
				columns={tableColumns}
				table={table}
				dataTable={tableData}
			/>
			<DataTablePagination
				isPendingPaginationData={isPending}
				dataPagination={drivers}
			/>
		</Page>
	);
}
