import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

/**
 * A custom label component.
 *
 * @component
 * @example
 * ```tsx
 * <Root htmlFor="inputId" className="label">Label Text</Root>
 * ```
 */
const Root = React.forwardRef<
	HTMLLabelElement,
	React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
>((props, ref) => (
	<label
		ref={ref}
		{...props}
	/>
));

/**
 * CSS classes for label variants.
 * @remarks
 * These classes define the styling for labels based on different variants.
 */
const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70");

/**
 * A custom label component.
 *
 * @param {string} className - Additional CSS class names.
 * @returns {JSX.Element} The rendered Label component.
 * @component
 * @example
 * ```tsx
 * <Label variant="primary" className="custom-label">Hello, World!</Label>
 * ```
 */
const Label = React.forwardRef<
	React.ElementRef<typeof Root>,
	React.ComponentPropsWithoutRef<typeof Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
	<Root
		ref={ref}
		className={cn({ inputs: [labelVariants(), className] })}
		{...props}
	/>
));
Label.displayName = Root.displayName;

export { Label, Root };
