import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import type { GetMeData, OpeningHour } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { getApiUserMeQueryOptions, usePostApiNccTeamEditHook } from "api/gen";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import type { ReStripeUrl } from "./_types";
import { useGetApiTeamSubscriptionsStripeNccTeamIdHook } from "./_useGetApiTeamSubscriptionsStripeNccTeamIdHook";

export const Route = createFileRoute("/webpanel/settings/")({
	component: () => Settings(),
	loader: ({ context: { queryClient } }) => {
		queryClient.ensureQueryData(getApiUserMeQueryOptions());
	},
});

const weekdayTranslation = {
	Monday: "Lunedì",
	Tuesday: "Martedì",
	Wednesday: "Mercoledì",
	Thursday: "Giovedì",
	Friday: "Venerdì",
	Saturday: "Sabato",
	Sunday: "Domenica",
};

const adjustTime = (time: string): string => {
	return time === "24:00" ? "23:59" : time;
};

const Settings = () => {
	const buildEmptyOpening = (dayOfWeek: string) => ({
		"@type": "OpeningHoursSpecification",
		dayOfWeek,
		opens: "",
		closes: "",
	});

	const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
	const { mutate: apiNccTeamEditHook } = usePostApiNccTeamEditHook();
	const getMeCache = !loadingUserData ? (data as GetMeData) : undefined;
	const [name, surname] = !loadingUserData ? (getMeCache?.data.name ?? "").split(" ") : ["", ""];

	const emptyOpening = [
		"Monday",
		"Monday",
		"Tuesday",
		"Tuesday",
		"Wednesday",
		"Wednesday",
		"Thursday",
		"Thursday",
		"Friday",
		"Friday",
		"Saturday",
		"Saturday",
		"Sunday",
		"Sunday",
	].map(buildEmptyOpening);

	const normalizeOpeningHours = (openingHours: OpeningHour[]) => {
		return openingHours.map(hour => ({
			...hour,
			opens: adjustTime(hour.opens),
			closes: adjustTime(hour.closes),
		}));
	};

	const [openings, setOpenings] = useState<OpeningHour[] | undefined>(
		getMeCache?.data.nccteams[0]?.opening_hours.length === 0 ||
			getMeCache?.data.nccteams[0]?.opening_hours === undefined
			? emptyOpening
			: normalizeOpeningHours(getMeCache?.data.nccteams[0]?.opening_hours),
	);

	const [nccTeamId, setNccTeamId] = useState<number | undefined>(getMeCache?.data.nccteams[0]?.id);
	const { refetch: refetchStripeData } = useGetApiTeamSubscriptionsStripeNccTeamIdHook({
		ncc_team_id: nccTeamId,
	});

	// Function to handle changes in opens and closes time
	const handleTimeChange = (openingIndex: number, key: keyof OpeningHour, value: string) => {
		setOpenings(prevOpenings => {
			const updatedOpenings = [...(prevOpenings ?? [])] as OpeningHour[];
			const opening = updatedOpenings[openingIndex] ?? ({ dayOfWeek: "", opens: "", closes: "" } as OpeningHour);
			opening[key] = value;

			return updatedOpenings;
		});
	};

	const InfoList = useMemo(
		() => ({
			Nome: name,
			Cognome: surname,
			"Ragione Sociale": getMeCache?.data?.nccteams[0]!.name,
			Email: getMeCache?.data?.email,
			Indirizzo: getMeCache?.data?.city,
		}),
		[name, surname, getMeCache],
	);

	return (
		<div className="overflow-y-scroll w-full p-4 pt-10">
			<h1 className="font-bold text-lg mb-8">{"Impostazioni"}</h1>
			<div className="grow-10 shrink-10" />
			<div className="grow-1 justify-start flex gap-12">
				<div>
					<h1 className="font-bold text-lg mb-8">Anagrafica</h1>
					{Object.entries(InfoList).map(([key, value]) => (
						<h3 key={key}>
							<p className="font-xs font-bold text-foreground-muted mt-2">{key}</p>
							{value}
						</h3>
					))}
				</div>
				<div>
					<h1 className="font-bold text-lg mb-8">Orari e giorni di servizio</h1>
					<div className="grid xl:grid-cols-2 gap-12 mb-4">
						{openings
							?.map(opening => opening.dayOfWeek)
							.filter((day, index, daysList) => daysList.indexOf(day) === index)
							.map(day => (
								<div
									key={day}
									className="flex items-center justify-between rounded-container border-background-content border-2 gap-8  p-4"
								>
									{weekdayTranslation[day as keyof typeof weekdayTranslation]}
									<div>
										{openings
											.map((opening, index) => ({ ...opening, index }))
											.filter(opening => opening.dayOfWeek === day)
											.map(opening => (
												<div key={`${day}-${opening.opens}-${opening.closes}-${opening.index}`}>
													<div className="flex gap-8 ">
														<Label className="leading-relaxed">
															dalle ore{" "}
															<Input
																type="time"
																defaultValue={opening.opens}
																onChange={e => handleTimeChange(opening.index, "opens", e.target.value)}
																variant={"outline"}
															/>
														</Label>
														<Label className="leading-relaxed">
															alle ore{" "}
															<Input
																type="time"
																defaultValue={opening.closes}
																onChange={e => handleTimeChange(opening.index, "closes", e.target.value)}
																variant={"outline"}
															/>
														</Label>
													</div>
												</div>
											))}
									</div>
								</div>
							))}
					</div>

					<div className="flex flex-row">
						<Button
							className="ml-auto mr-4"
							variant={"outline"}
							onClick={e => {
								if (getMeCache?.data.nccteams[0]?.id && !loadingUserData) {
									setNccTeamId(getMeCache?.data.nccteams[0]?.id);
									refetchStripeData().then(res => {
										e.preventDefault();
										window.open((res.data as unknown as ReStripeUrl).data.url, "stripe");
									});
								}
							}}
						>
							Billing
						</Button>
						<Button
							variant={"outline"}
							onClick={e => {
								if (!loadingUserData)
									apiNccTeamEditHook(
										{
											opening_hours: JSON.stringify(openings).toString(),
											id: getMeCache?.data.nccteams[0]?.id as number,
											description: "edit",
										},
										{
											onSuccess: () => {
												toast.success("Orari aggiornati con successo");
											},
											onError: () => {
												toast.error("Errore durante l'aggiornamento degli orari");
											},
										},
									);
							}}
						>
							Salva
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
