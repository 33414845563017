import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/lib/utils";
import type { InfiniteData } from "@tanstack/react-query";
import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { columnsDistance, columnsRates } from "./_columns";
import type { IDataDistanceTable, IDistanceData } from "./_types";

export function DistanceTable<T extends InfiniteData<IDataDistanceTable, unknown>>({
	dataDistanceTable,
	rideTiersPending,
	fetchNextPage,
}: { dataDistanceTable: T | undefined; rideTiersPending: boolean; fetchNextPage: () => void }) {
	//we need a reference to the scrolling element for logic down below
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const tableColumns = useMemo<ColumnDef<IDistanceData>[]>(
		() =>
			rideTiersPending
				? columnsDistance.map(column => ({ ...column, cell: () => <Skeleton className="h-2 w-full" /> }))
				: columnsDistance,
		[rideTiersPending, columnsDistance],
	);

	//flatten the array of arrays from the useInfiniteQuery hook
	const tableData = useMemo<IDistanceData[]>(
		() => (rideTiersPending ? Array(30).fill({}) : dataDistanceTable?.pages.flatMap(page => page.data) ?? []),
		[dataDistanceTable?.pages, rideTiersPending],
	);
	const totalRowCount = dataDistanceTable?.pages?.[0]?.meta.total ?? 0;
	const totalFetched = tableData.length;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 500 && !rideTiersPending && totalFetched < totalRowCount) {
					fetchNextPage();
				}
			}
		},
		[fetchNextPage, rideTiersPending, totalFetched, totalRowCount],
	);

	//a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
	useEffect(() => {
		fetchMoreOnBottomReached(tableContainerRef.current);
	}, [fetchMoreOnBottomReached]);

	const table = useReactTable({
		columns: tableColumns,
		data: tableData,
		getCoreRowModel: getCoreRowModel(),
	});

	const { rows } = table.getRowModel();

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5,
	});

	return (
		<div className="flex items-center justify-between max-w-max mt-2">
			<div
				className="h-[300px] overflow-auto relative"
				onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
				ref={tableContainerRef}
			>
				<Table className="bg-black">
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<TableHead key={header.id}>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody className={cn({ inputs: [`height-[${rowVirtualizer.getTotalSize()}px]`] })}>
						{rowVirtualizer.getVirtualItems().map(virtualRow => {
							const row = rows[virtualRow.index];
							if (row) {
								return (
									<TableRow
										data-index={virtualRow.index} //needed for dynamic row height measurement
										ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
										key={row.id}
									>
										{row.getVisibleCells().map(cell => (
											<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
										))}
									</TableRow>
								);
							}
							<TableRow>
								<TableCell
									colSpan={columnsRates.length}
									className="h-24 text-center"
								>
									Nessun risultato trovato
								</TableCell>
							</TableRow>;
						})}
					</TableBody>
				</Table>
			</div>
		</div>
	);
}
