import type { TypeToZod } from "@/types";
import { type Currency, EUR } from "@dinero.js/currencies";
import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { type Dinero, dinero, toDecimal } from "dinero.js";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

// keep local time as a reference
dayjs.extend(utc);

/**
 * Combines multiple class names into a single string.
 *
 * @param inputs - An array of class names to be combined.
 * @returns A string representing the combined class names.
 */
export function cn({ inputs = [] }: { inputs?: ClassValue[] } = {}): string {
	return twMerge(clsx(inputs));
}

/**
 * Formats a date into a string with the format "DD/MM/YY".
 * @param {Date | string | null | undefined} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: Date | string | null | undefined): string => {
	return dayjs.utc(date).format("DD/MM/YY");
};

/**
 * Formats a date and time into the "DD/MM/YY HH:mm" format.
 *
 * @param date - The date to format. Can be a Date object, a string, null, or undefined.
 * @returns {string} The formatted date and time as a string.
 */
export const formatDateAndTime = (date: Date | string | null | undefined): string => {
	return dayjs.utc(date).format("DD/MM/YY HH:mm");
};

/**
 * Formats a date as a string in the format "YY-MM-DD".
 *
 * @param {Date | string | null | undefined} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatDateMinus = (date: Date | string | null | undefined): string => {
	return dayjs.utc(date).format("YY-MM-DD");
};

/**
 * Converts a floating-point number to a Dinero object.
 * @param float - The floating-point number to convert.
 * @param currency - The currency to use for the Dinero object. Defaults to EUR.
 * @returns The Dinero object representing the converted amount.
 */
function dineroFromFloat({ float, currency = EUR }: { float: number; currency?: Currency<number> }): Dinero<number> {
	const factor = Number(currency.base) ** currency.exponent;
	const amount = Math.round(float * factor);

	return dinero({ amount, currency });
}

/**
 * Formats a Dinero object as a localized currency string.
 *
 * @param dineroObject - The Dinero object to format.
 * @param locale - The locale to use for formatting.
 * @param options - Additional options for formatting.
 * @returns {string} The formatted currency string.
 */
function intlFormat(dineroObject: Dinero<number>, locale: Intl.LocalesArgument, options = {}): string {
	function transformer({ value, currency }: { value: string; currency: { code: string & {} } }) {
		return Number.parseFloat(value).toLocaleString(locale, {
			...options,
			style: "currency",
			currency: currency.code,
		});
	}

	return toDecimal(dineroObject, transformer);
}

/**
 * Formats a currency value into a localized string representation.
 * @param value - The currency value to format.
 * @returns { string | undefined } The formatted currency value as a string.
 */
export const formatCurrency = (value?: number | string | null): string | undefined => {
	if (typeof value === "string") {
		const convertedValueToNumber = Number.parseFloat(value);
		if (convertedValueToNumber && !Number.isNaN(convertedValueToNumber)) {
			const formattedValue = dineroFromFloat({ float: convertedValueToNumber });

			return intlFormat(formattedValue, "it-IT");
		}
	}
	if (value && !Number.isNaN(value)) {
		const formattedValue = dineroFromFloat({ float: value as number });

		return intlFormat(formattedValue, "it-IT");
	}
};

/**
 * Creates a Zod schema from a given type.
 *
 * @template T - The type to create the Zod schema from.
 * @param {TypeToZod<T>} obj - The object representing the type.
 * @returns - The Zod schema created from the type.
 */
export function createZodFromType<T>(obj: TypeToZod<T>) {
	return z.object(obj);
}
