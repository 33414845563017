import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select } from "@/components/ui/select";
import { useNccTeamIdStore } from "@/hooks/store/main";
import { cn, createZodFromType } from "@/lib/utils";
import { queryClient } from "@/main";
import type { ITransportVehicleTypeAll } from "@/routes/webpanel/veichles/_types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	type PostApiTransportTierCreateMutationRequest,
	useGetApiTransportVehicleTypeAllHook,
	usePostApiTransportTierCreateHook,
} from "api/gen";
import { useState } from "react";
import CurrencyInput, { type CurrencyInputProps } from "react-currency-input-field";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GoPlusCircle } from "react-icons/go";
import { z } from "zod";

const FormSchema = createZodFromType<PostApiTransportTierCreateMutationRequest>({
	activation_fee: z.string(),
	activation_fee_reason: z.string().max(128),
	name: z.string(),
	ncc_team_id: z.number(),
	vehicle_type_id: z.number(),
	max_seats: z.number().default(0),
	rules: z.string().default(""),
	description: z.string().max(100),
});

export const AddVeichle = (props: { className: string }) => {
	const [isDialogOpenAddVeichle, setIsDialogOpenAddVeichle] = useState(false);
	const { data: transportVehicleTypeAll } = useGetApiTransportVehicleTypeAllHook();
	const transportVehicleTypeAllRes = transportVehicleTypeAll as ITransportVehicleTypeAll | undefined;
	const { nccTeamId } = useNccTeamIdStore();
	const { mutate: transportTierCreate } = usePostApiTransportTierCreateHook(undefined, {
		mutation: { mutationKey: [{ nccTeam_id: nccTeamId, available: true }] },
	});
	const [typeVehicle, setTypeVehicle] = useState<number>(0);
	const [activationFee, setActivationFee] = useState<string | undefined>("0");

	const form = useForm<z.infer<typeof FormSchema>>({
		mode: "all",
		resolver: zodResolver(FormSchema),
		defaultValues: {
			activation_fee: "0",
			activation_fee_reason: "new tier",
			name: "",
			ncc_team_id: nccTeamId,
			vehicle_type_id: 0,
			// max_seats: 0,
			rules: "",
			description: "",
		},
	});

	const handleActivationFeeValue: CurrencyInputProps["onValueChange"] = value => setActivationFee(value);

	function onSubmit(data: z.infer<typeof FormSchema>) {
		data.vehicle_type_id = typeVehicle;
		data.activation_fee = activationFee?.replace(",", ".") ?? "0";
		transportTierCreate(data, {
			onSuccess() {
				queryClient.invalidateQueries({ queryKey: ["rideTiers", `/api/transport/tier/list/${nccTeamId}`] });
				toast.success("Ride tier creato con successo");
				setIsDialogOpenAddVeichle(false);
			},
			onError() {
				toast.error("Errore durante la creazione del ride tier");
				setIsDialogOpenAddVeichle(false);
			},
		});
	}

	return (
		<div>
			<GoPlusCircle
				className={cn({ inputs: ["w-5 h-5", props.className] })}
				onClick={() => setIsDialogOpenAddVeichle(true)}
			/>
			<Dialog
				isOpen={isDialogOpenAddVeichle}
				onClose={() => setIsDialogOpenAddVeichle(false)}
				title="Aggiungi"
			>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="w-full space-y-6"
					>
						<div className="grid sm:grid-cols-2  gap-4 content-center">
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Nome tariffa</FormLabel>
										<FormControl>
											<Input
												type="text"
												placeholder="inserisci il nome"
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="description"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Descrizione</FormLabel>
										<FormControl>
											<Input
												type="text"
												placeholder="inserisci la descrizione"
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="activation_fee"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Costo attivazione</FormLabel>
										<FormControl>
											<CurrencyInput
												customInput={Input}
												placeholder="inserisci il costo di attivazione"
												type="string"
												decimalsLimit={2}
												value={activationFee}
												onValueChange={handleActivationFeeValue}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="vehicle_type_id"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Tipologia</FormLabel>
										<Select
											options={
												transportVehicleTypeAllRes?.data?.map(vehicleType => ({
													value: vehicleType.id,
													label: `${vehicleType.title} (${vehicleType.seats - 1})`,
												})) ?? []
											}
											{...field}
											onChange={(value: unknown): void => {
												setTypeVehicle(value as number);
											}}
											title="Seleziona tipo veicolo"
										/>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex justify-center items-center space-x-2">
							<Button
								type="submit"
								size={"xl"}
							>
								Salva
							</Button>
							<Button
								type="button"
								size={"xl"}
								variant={"outline"}
								onClick={() => setIsDialogOpenAddVeichle(false)}
							>
								Annulla
							</Button>
						</div>
					</form>
				</Form>
			</Dialog>
		</div>
	);
};
