import type { IDataDistanceTable } from "@/routes/webpanel/rates/_types";
import {
	type InfiniteData,
	type QueryKey,
	type UseInfiniteQueryOptions,
	type UseInfiniteQueryResult,
	keepPreviousData,
	useInfiniteQuery,
} from "@tanstack/react-query";
import type {
	GetApiTransportTierPriceVariantPricingListRidetierId403,
	GetApiTransportTierPriceVariantPricingListRidetierIdHeaderParams,
	GetApiTransportTierPriceVariantPricingListRidetierIdPathParams,
	GetApiTransportTierPricingPricingListRidetierIdPathParams,
} from "api/gen";
import type client from "api/templatesSDK/client";
import axiosClient from "api/templatesSDK/client";

type GetApiTransportTierPriceVariantPricingListRidetierIdClient = typeof client<
	IDataDistanceTable,
	GetApiTransportTierPriceVariantPricingListRidetierId403,
	never
>;
type GetApiTransportTierPriceVariantPricingListRidetierId = {
	data: IDataDistanceTable;
	error: GetApiTransportTierPriceVariantPricingListRidetierId403;
	request: never;
	pathParams: GetApiTransportTierPriceVariantPricingListRidetierIdPathParams;
	queryParams: never;
	headerParams: GetApiTransportTierPriceVariantPricingListRidetierIdHeaderParams;
	response: IDataDistanceTable;
	client: {
		parameters: Partial<Parameters<GetApiTransportTierPriceVariantPricingListRidetierIdClient>[0]>;
		return: Awaited<ReturnType<GetApiTransportTierPriceVariantPricingListRidetierIdClient>>;
	};
};
export const getApiTransportTierPriceVariantPricingListRidetierIdQueryKey = (
	rideTierId: GetApiTransportTierPriceVariantPricingListRidetierIdPathParams["rideTier_id"],
) => ["/api/transport/tier/price-variant/list/:rideTier_id"] as const;
export type GetApiTransportTierPriceVariantPricingListRidetierIdQueryKey = ReturnType<
	typeof getApiTransportTierPriceVariantPricingListRidetierIdQueryKey
>;

export function useGetInfiniteScrollAdditionalFee<
	TData = InfiniteData<GetApiTransportTierPriceVariantPricingListRidetierId["response"]>,
	TQueryData = GetApiTransportTierPriceVariantPricingListRidetierId["response"],
	TQueryKey extends QueryKey = GetApiTransportTierPriceVariantPricingListRidetierIdQueryKey,
>(
	rideTierId: GetApiTransportTierPricingPricingListRidetierIdPathParams["rideTier_id"],
	options: {
		query?: Partial<
			UseInfiniteQueryOptions<
				GetApiTransportTierPriceVariantPricingListRidetierId["response"],
				GetApiTransportTierPriceVariantPricingListRidetierId["error"],
				TData,
				TQueryData,
				TQueryKey
			>
		>;
		client?: GetApiTransportTierPriceVariantPricingListRidetierId["client"]["parameters"];
	} = {},
): UseInfiniteQueryResult<TData, GetApiTransportTierPriceVariantPricingListRidetierId["error"]> & {
	queryKey: TQueryKey;
} {
	const { query: queryOptions, client: clientOptions = {} } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getApiTransportTierPriceVariantPricingListRidetierIdQueryKey(rideTierId);
	const query = useInfiniteQuery({
		enabled: !!rideTierId,
		queryKey,
		queryFn: async ({ pageParam }: { pageParam: number }) => {
			const res = await axiosClient<IDataDistanceTable, GetApiTransportTierPriceVariantPricingListRidetierId["error"]>({
				method: "get",
				url: `/api/transport/tier/price-variant/list/${rideTierId}?page=${pageParam ?? 1}`,
				...clientOptions,
			});
			return res.data;
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage.data.length ? allPages.length + 1 : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
		placeholderData: keepPreviousData,
	}) as UseInfiniteQueryResult<TData, GetApiTransportTierPriceVariantPricingListRidetierId["error"]> & {
		queryKey: TQueryKey;
	};
	query.queryKey = queryKey as TQueryKey;
	return query;
}
