import { cn } from "@/lib/utils";

/**
 * Renders a skeleton component that provides a loading state for content.
 *
 * @component
 * @example
 * <Skeleton className="h-12 w-12 rounded-full" />
 * @param {React.HTMLAttributes<HTMLDivElement>} props - The props for the Skeleton component.
 * @param {string} [props.className] - Additional CSS class name(s) to apply to the component.
 * @returns {JSX.Element} The rendered Skeleton component.
 *
 * @example
 * ```tsx
 * const App: React.FC = (loading) => {
 *  return loading ? <Skeleton className="h-12 w-12 rounded-full" /> : <p>Hello world</p>
 * };
 * ```
 */
function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
	return (
		<div
			className={cn({ inputs: ["animate-pulse rounded-md bg-muted", className] })}
			{...props}
		/>
	);
}

export { Skeleton };
