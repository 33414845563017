import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import {
	FloatingFocusManager,
	FloatingOverlay,
	autoUpdate,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
	useTransitionStyles,
} from "@floating-ui/react";
import { CgClose as X } from "react-icons/cg";

/**
 * Props for the Dialog component.
 */
interface DialogProps {
	isOpen: boolean; // Indicates whether the dialog is open or not
	onClose: () => void; // Callback function to close the dialog
	title: string; // Title of the dialog
	children?: React.ReactNode; // Content of the dialog
}

/**
 * Dialog component that displays a modal dialog.
 * @param {DialogProps} props - The props for the Dialog component.
 * @returns {React.ReactNode} The rendered Dialog component.
 *
 * @example
 * ```tsx
 *	const App: React.FC = () => {
 *	    const [isDialogOpen, setIsDialogOpen] = useState(false);
 *
 *	    const openDialog = () => setIsDialogOpen(true);
 *	    const closeDialog = () => setIsDialogOpen(false);
 *
 *	    return (
 *	    	<div className="p-4">
 *	    		<utton
 *	    			onClick={openDialog}
 *	    			className="px-4 py-2 bg-accent text-white rounded-md"
 *	    		>
 *	    			Open Dialog
 *	    		</utton>
 *
 *	    		<Dialog
 *	    			isOpen={isDialogOpen}
 *	    			onClose={closeDialog}
 *	    			title="Dialog Title"
 *	    		>
 *	    			<p>This is the content of the dialog. Place your custom content here.</p>
 *	    		</Dialog>
 *	    	</div>
 *	    );
 *	};
 * ```
 */
const Dialog = ({ isOpen, onClose, title, children }: DialogProps): React.ReactNode => {
	const { refs, context } = useFloating({
		open: isOpen,
		onOpenChange: () => onClose(),
		transform: true,
		whileElementsMounted: autoUpdate,
		// placement: props.placement ?? "bottom",
	});

	const { isMounted, styles } = useTransitionStyles(context, {
		duration: 500,
		initial: () => ({
			opacity: 0,
			transform: "scale(0.8) translateY(-10px)",
		}),
		common: {
			transitionTimingFunction: "cubic-bezier(.3,2,.1,1)",
			zIndex: 100,
			width: "inherit",
		},
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context);

	// Merge all the interactions into prop getters
	const { getFloatingProps } = useInteractions([click, dismiss, role]);

	return (
		<>
			{/* <button ref={refs.setReference} {...getReferenceProps()}>
				Reference element
			</button> */}

			{isMounted ? (
				<FloatingOverlay
					// lockScroll
					className={cn({
						inputs: [
							"z-50 flex justify-center items-center bg-background-base/20 backdrop-blur",
							isOpen && "animate-show",
							!isOpen && "animate-hide",
						],
					})}
				>
					<FloatingFocusManager context={context}>
						<div
							ref={refs.setFloating}
							{...getFloatingProps()}
							style={{ ...styles }}
							className="flex items-center justify-center"
						>
							<div className="bg-background-content rounded-container p-5">
								<div className="flex justify-between items-center pb-2">
									<h3 className="text-lg text-center font-medium justify-center flex-1">{title}</h3>
									<Button
										variant={"transparent"}
										onClick={onClose}
										className="text-foreground hover:text-foreground-muted"
									>
										{/* Close Icon */}
										<X className="w-4" />
									</Button>
								</div>
								<div className="my-4 justify-center items-center">
									{/* Dialog content */}
									{children}
								</div>
							</div>
						</div>
					</FloatingFocusManager>
				</FloatingOverlay>
			) : null}
		</>
	);
};

export default Dialog;
