import type { INccTeamIdState, IRideIdState, IVeichleRideTypeIdState } from "@/hooks/store/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

/**
 * Custom hook for managing ride ID state.
 *
 * @returns An object containing the ride ID state and a function to update it.
 */
export const useRideIdStore = create<IRideIdState>()(
	persist(
		set => ({
			rideId: undefined,
			setRideId: item => set(state => ({ ...state, rideId: item })),
		}),
		{
			name: "ride-id-storage", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
);

/**
 * Custom hook for managing the veichle ride type ID state.
 *
 * @returns An object containing the veichle ride type ID state and a function to update it.
 */
export const useVeichleRideTypeIdStore = create<IVeichleRideTypeIdState>()(
	persist(
		set => ({
			veichleRideTypeId: undefined,
			setVeichleRideTypeId: item => set(state => ({ ...state, veichleRideTypeId: item })),
		}),
		{
			name: "veichle-ride-id-storage", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
);

/**
 * Custom hook for managing the NccTeamId state and data.
 *
 * @returns An object containing the NccTeamId state and related functions.
 */
export const useNccTeamIdStore = create<INccTeamIdState>()(
	persist(
		set => ({
			nccTeamId: undefined,
			nccTeamData: undefined,
			setNccTeamId: item => set(state => ({ ...state, nccTeamId: item })),
			setNccTeamData: item => set(state => ({ ...state, nccTeamData: item })),
		}),
		{
			name: "nccTeam-id-storage", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
);
