import { Calendar } from "@/components/ui/calendar";
import Dropdown from "@/components/ui/dropdown";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Select } from "@/components/ui/select";
import { cn, formatDate } from "@/lib/utils";
import toast from "react-hot-toast";
import { GoCalendar } from "react-icons/go";
import type { ITransportVehicleTypeAll } from "./_types";

import { formatDateMinus } from "@/lib/utils";
import { queryClient } from "@/main";

import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import type { z } from "zod";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { ResGarages } from "@/routes/webpanel/garage/_types";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
	getApiUserMeQueryOptions,
	postApiTransportVehicleCreateMutationRequestSchema,
	useGetApiNccTeamNccLocationListNccTeamIdHook,
	useGetApiTransportVehicleTypeAllHook,
	usePostApiTransportVehicleCreateHook,
} from "api/gen";

export const VeichlesUtilsForm = ({ closeDialog }: { closeDialog: () => void }) => {
	const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeData = data as GetMeData | undefined;

	const { data: GarageQuery } = useGetApiNccTeamNccLocationListNccTeamIdHook(
		(getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number,
		undefined,
		{ query: { enabled: getMeData !== undefined && !loadingUserData } },
	);
	const garages = GarageQuery as ResGarages | undefined;

	const { data: transportVehicleTypeAll } = useGetApiTransportVehicleTypeAllHook();
	const transportVehicleTypeAllRes = transportVehicleTypeAll as ITransportVehicleTypeAll | undefined;
	const { mutate: CreateVeichle } = usePostApiTransportVehicleCreateHook();

	const [registerDate, setRegisterDate] = useState<Date | undefined>();

	const [garageVehicle, setGarageVehicle] = useState<number>(0);
	const [typeVehicle, setTypeVehicle] = useState<number>(0);

	const form = useForm<z.infer<typeof postApiTransportVehicleCreateMutationRequestSchema>>({
		mode: "all",
		resolver: zodResolver(postApiTransportVehicleCreateMutationRequestSchema),
		defaultValues: {
			registered_at: formatDateMinus(registerDate),
			vehicle_type_id: 0,
			ncc_team_id: (getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number,
			ncc_location_id: 0,
			seats: 0,
		},
	});

	function onSubmit(data: z.infer<typeof postApiTransportVehicleCreateMutationRequestSchema>) {
		const setupData: z.infer<typeof postApiTransportVehicleCreateMutationRequestSchema> = {
			...data,
			registered_at: formatDateMinus(registerDate),
			ncc_team_id: (getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number,
			ncc_location_id: garageVehicle,
			vehicle_type_id: typeVehicle,
		};
		CreateVeichle(setupData, {
			onError() {
				toast.error("Errore durante la creazione del veicolo");
			},
			onSuccess() {
				queryClient.invalidateQueries({
					queryKey: [
						`/api/transport/vehicle/list/${(getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number}`,
					],
				});
				toast.success("Veicolo creato con successo");
				closeDialog();
			},
		});
	}

	return (
		<Form {...form}>
			<form
				className="w-full items-center justify-center space-y-6"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<div className="grid grid-cols-2 gap-4 items-center justify-center ">
					<FormField
						control={form.control}
						name="plaque"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Targa</FormLabel>
								<FormControl>
									<Input
										type="text"
										placeholder="inserisci la targa"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>{" "}
					<FormField
						control={form.control}
						name="registered_at"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Data di registrazione</FormLabel>
								<Dropdown
									clickOnClose={false}
									trigger={
										<FormControl>
											<Button
												type="button"
												variant={"default"}
												className={cn({
													inputs: ["w-full flex justify-between", !field.value && "text-muted-foreground"],
												})}
											>
												{registerDate ? formatDate(registerDate) : <span>Seleziona una data</span>}
												<GoCalendar />
											</Button>
										</FormControl>
									}
									{...field}
								>
									<Calendar
										mode="single"
										selected={registerDate}
										className="bg-transparent rounded-none"
										onSelect={e => {
											setRegisterDate(e);
										}}
										disabled={date => date > new Date() || date < new Date("1900-01-01")}
										initialFocus
									/>
								</Dropdown>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="brand"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Brand</FormLabel>
								<FormControl>
									<Input
										type="text"
										placeholder="inserisci il brand"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="model"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Modello</FormLabel>
								<FormControl>
									<Input
										type="text"
										placeholder="inserisci il modello"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="ncc_location_id"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Autorimessa</FormLabel>
								<Select
									options={
										garages?.data?.map(garageEl => ({
											value: garageEl.id.toString(),
											label: garageEl.name,
										})) ?? []
									}
									{...field}
									onChange={(value: unknown): void => {
										setTypeVehicle(value as number);
										setGarageVehicle(value as number);
									}}
									title="Seleziona autorimessa"
								/>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="vehicle_type_id"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Tipologia</FormLabel>
								<Select
									options={
										transportVehicleTypeAllRes?.data?.map(vehicleType => ({
											value: vehicleType.id,
											label: `${vehicleType.title} (${vehicleType.seats - 1})`,
										})) ?? []
									}
									{...field}
									onChange={(value: unknown): void => {
										setTypeVehicle(value as number);
									}}
									title="Seleziona tipo veicolo"
								/>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="flex justify-center items-center space-x-2">
					<Button
						type="submit"
						size={"xl"}
					>
						Salva
					</Button>
					<Button
						type="button"
						size={"xl"}
						variant={"outline"}
						onClick={closeDialog}
					>
						Annulla
					</Button>
				</div>
			</form>
		</Form>
	);
};
