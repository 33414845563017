import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";

/**
 * Props for the Input component.
 */
interface InputProps extends InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

/**
 * Variants for the input component.
 */
export const inputVariants = cva(" flex flex-row items-center justify-center ", {
	variants: {
		variant: {
			default: "px-3 text-foreground-900 border-muted border-input",
			transparent: "bg-transparent border-transparent hover:bg-foreground/10 bg-blend-exclusion border border-input",
			ghost: "bg-transparent text-center outline-none text-foreground-50 border-b-[1px] rounded-none",
			outline: "px-3 border-muted bg-background-content text-foreground-50 border",
			black:
				"px-3 bg-background-base border-background-base hover:bg-background-content hover:border-background-content border border-input",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

/**
 * A custom input component.
 *
 * @param {object} props - The input component props.
 * @param {string} props.className - The CSS class name for the input element.
 * @param {string} props.type - The type of the input element.
 * @param {React.Ref<HTMLInputElement>} ref - The ref for the input element.
 * @returns {JSX.Element} The rendered input component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  return (
 * <Input type="text" className="my-input" placeholder="Enter your name" />
 *  );
 * };
 * ```
 */
const Input = forwardRef<HTMLInputElement, InputProps>(({ className, variant, type, ...props }, ref) => {
	return (
		<input
			type={type}
			className={cn({
				inputs: [
					"w-full text-muted h-10 rounded-md bg-foreground-50 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-muted focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
					inputVariants({ variant, className }),
				],
			})}
			ref={ref}
			{...props}
		/>
	);
});
Input.displayName = "Input";

export { Input };
