import type { PagedRideTiers } from "@/routes/webpanel/rates/_types";
import {
	type InfiniteData,
	type QueryKey,
	type UseInfiniteQueryOptions,
	type UseInfiniteQueryResult,
	keepPreviousData,
	useInfiniteQuery,
} from "@tanstack/react-query";
import type {
	GetApiTransportTierPricingPricingListRidetierId403,
	GetApiTransportTierPricingPricingListRidetierIdHeaderParams,
	GetApiTransportTierPricingPricingListRidetierIdPathParams,
} from "api/gen";
import type client from "api/templatesSDK/client";
import axiosClient from "api/templatesSDK/client";

type GetApiTransportTierPricingPricingListRidetierIdClient = typeof client<
	PagedRideTiers,
	GetApiTransportTierPricingPricingListRidetierId403,
	never
>;
type GetApiTransportTierPricingPricingListRidetierId = {
	data: PagedRideTiers;
	error: GetApiTransportTierPricingPricingListRidetierId403;
	request: never;
	pathParams: GetApiTransportTierPricingPricingListRidetierIdPathParams;
	queryParams: never;
	headerParams: GetApiTransportTierPricingPricingListRidetierIdHeaderParams;
	response: PagedRideTiers;
	client: {
		parameters: Partial<Parameters<GetApiTransportTierPricingPricingListRidetierIdClient>[0]>;
		return: Awaited<ReturnType<GetApiTransportTierPricingPricingListRidetierIdClient>>;
	};
};
export const getApiTransportTierPricingPricingListRidetierIdQueryKey = (
	rideTierId: GetApiTransportTierPricingPricingListRidetierIdPathParams["rideTier_id"],
) => ["/api/transport/tier/pricing/list/:rideTier_id"] as const;
export type GetApiTransportTierPricingPricingListRidetierIdQueryKey = ReturnType<
	typeof getApiTransportTierPricingPricingListRidetierIdQueryKey
>;

export function useGetInfiniteScrollDistancePricing<
	TData = InfiniteData<GetApiTransportTierPricingPricingListRidetierId["response"]>,
	TQueryData = GetApiTransportTierPricingPricingListRidetierId["response"],
	TQueryKey extends QueryKey = GetApiTransportTierPricingPricingListRidetierIdQueryKey,
>(
	rideTierId: GetApiTransportTierPricingPricingListRidetierIdPathParams["rideTier_id"],
	options: {
		query?: Partial<
			UseInfiniteQueryOptions<
				GetApiTransportTierPricingPricingListRidetierId["response"],
				GetApiTransportTierPricingPricingListRidetierId["error"],
				TData,
				TQueryData,
				TQueryKey
			>
		>;
		client?: GetApiTransportTierPricingPricingListRidetierId["client"]["parameters"];
	} = {},
): UseInfiniteQueryResult<TData, GetApiTransportTierPricingPricingListRidetierId["error"]> & {
	queryKey: TQueryKey;
} {
	const { query: queryOptions, client: clientOptions = {} } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getApiTransportTierPricingPricingListRidetierIdQueryKey(rideTierId);
	const query = useInfiniteQuery({
		enabled: !!rideTierId,
		queryKey,
		queryFn: async ({ pageParam }: { pageParam: number }) => {
			const res = await axiosClient<
				GetApiTransportTierPricingPricingListRidetierId["data"],
				GetApiTransportTierPricingPricingListRidetierId["error"]
			>({
				method: "get",
				url: `/api/transport/tier/pricing/list/${rideTierId}?page=${pageParam ?? 1}`,
				...clientOptions,
			});
			return res.data;
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage.data.length ? allPages.length + 1 : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
		placeholderData: keepPreviousData,
	}) as UseInfiniteQueryResult<TData, GetApiTransportTierPricingPricingListRidetierId["error"]> & {
		queryKey: TQueryKey;
	};
	query.queryKey = queryKey as TQueryKey;
	return query;
}
