import { OnOffSelect } from "@/components/ui/onOffSelect";
import { Skeleton } from "@/components/ui/skeleton";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { getApiUserMeQueryOptions, usePostApiTransportVehicleAvailabilityHook } from "api/gen";
import toast from "react-hot-toast";
import type { Veichles } from "./_types";

export const columnsVeichles: ColumnDef<Veichles>[] = [
	{
		accessorKey: "plaque",
		header: "Targa",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("plaque")}</div>,
	},
	{
		accessorKey: "model",
		header: "Modello",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("model")}</div>,
	},
	{
		accessorKey: "description",
		accessorFn: row => {
			return row.ncc_location.description;
		},
		header: "Autorimessa",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("description")}</div>,
	},
	{
		accessorKey: "name",
		header: "Tipologia",
		accessorFn: row => {
			return `${row.vehicle_type.name} (${row.vehicle_type.seats - 1})`;
		},
		// style: { whiteSpace: "unset" },
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("name")}</div>,
	},
	{
		accessorKey: "available",
		header: () => <div>Status</div>,
		cell: params => {
			const { row, cell } = params;
			const status = cell.getValue();
			const { mutate: ChangeVeichleStatus } = usePostApiTransportVehicleAvailabilityHook();

			const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
			const getMeData = data as GetMeData | undefined;
			return getMeData !== undefined ? (
				<div className="w-32 -my-2">
					<OnOffSelect
						title="Seleziona uno status"
						name="stato"
						size="sm"
						onChange={value =>
							ChangeVeichleStatus(
								{
									id: row.original.id,
									ncc_team_id: (getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number,
									available: value === "attivo",
								},
								{
									onSuccess() {
										toast.success("Stato del veicolo aggiornato con successo");
									},
									onError() {
										toast.error("Errore durante l'aggiornamento dello stato del veicolo");
									},
								},
							)
						}
						disabled={getMeData === undefined}
						options={[
							{ value: "attivo", label: "Attivo", status: "on" },
							{ value: "disattivo", label: "Disattivo", status: "off" },
						]}
						value={status ? "attivo" : "disattivo"}
					/>
				</div>
			) : (
				<Skeleton className="w-32 -my-2" />
			);
		},
	},
];
