import Pagination from "@/components/Pagination";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import type { IPagination } from "@/types";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

interface DataTablePaginationProps<TApiResponseData extends IPagination> {
	isPendingPaginationData: boolean;
	dataPagination: TApiResponseData;
}

/**
 * Renders the pagination component for a data table.
 *
 * @template TApiResponseData - The type of the API response data.
 * @param {DataTablePaginationProps<TApiResponseData>} props - The component props.
 * @returns {JSX.Element} - The rendered pagination component.
 */
export function DataTablePagination<TApiResponseData extends IPagination>({
	isPendingPaginationData,
	dataPagination,
}: DataTablePaginationProps<TApiResponseData>) {
	return (
		<div className="flex items-center justify-between space-x-2 py-4">
			{!isPendingPaginationData && dataPagination ? (
				<Pagination
					currentPage={dataPagination.meta.current_page}
					pageCount={dataPagination.meta.last_page}
					key={dataPagination.meta.current_page}
				/>
			) : (
				<div className="flex">
					<div className="flex flex-row gap-1 p-2 bg-background-content m-auto rounded">
						<Button
							disabled={true}
							className={
								"cursor-not-allowed bg-foreground border-foreground hover:bg-foreground hover:border-foreground text-muted"
							}
							size={"icon"}
						>
							<GoChevronLeft />
						</Button>
						{Array.from({ length: 3 }, (_, index) => index + 1).map(index => {
							return (
								<Skeleton
									aria-disabled={true}
									key={index}
									className="h-[26px] w-[22px] rounded-md"
								/>
							);
						})}
						<Button
							disabled={true}
							className={
								"cursor-not-allowed bg-foreground border-foreground hover:bg-foreground hover:border-foreground text-muted"
							}
							size={"icon"}
						>
							<GoChevronRight />
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}
