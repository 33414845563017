import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

/**
 * Props for the Slot component.
 */
interface SlotProps extends React.HTMLAttributes<HTMLElement> {
	/**
	 * The content to be rendered inside the Slot component.
	 */
	children?: React.ReactNode;
}

let Slot: React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>>;

/**
 * Props for the Button component.
 *
 * @interface ButtonProps
 * @extends React.ButtonHTMLAttributes<HTMLButtonElement>
 * @extends VariantProps<typeof buttonVariants>
 */
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const buttonVariants = cva(
	"rounded-control border flex flex-row items-center justify-center text-left whitespace-nowrap gap-2",
	{
		variants: {
			variant: {
				default: "text-foreground bg-accent border-accent hover:bg-accent-muted hover:border-accent-muted",
				transparent: "bg-transparent border-transparent hover:bg-foreground/10 bg-blend-exclusion",
				ghost: "bg-transparent border-transparent",
				outline: "bg-transparent border-foreground hover:bg-foreground/10",
				black: "bg-background-base border-background-base hover:bg-background-content hover:border-background-content",
			},
			size: {
				default: "p-2",
				hidden: "p-0 border-0",
				icon: "p-1",
				xs: "p-1 text-xs",
				sm: "p-2 text-xs",
				md: "p-2",
				lg: "px-3 py-2",
				xl: "px-4 py-2",
			},
			align: {
				default: "justify-center",
				left: "justify-start text-left",
				right: "end text-right",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
			align: "default",
		},
	},
);

/**
 * Renders the button component.
 * @param {ButtonProps} props - The button component props.
 * @param {React.Ref<HTMLButtonElement>} ref - The button component ref.
 * @returns {JSX.Element} The rendered button component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  return (
 * <Button
 * 	type="button"
 * 	size={"xl"}
 * 	align={"left"}
 * 	onClick={() => console.log("Clicked")}
 * >
 * 	Click me
 * </Button>
 *  )
 * };
 * ```
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, align, asChild = false, ...props }, ref) => {
		/**
		 * The component to be rendered.
		 * If `asChild` is true, it renders a `Slot` component.
		 * Otherwise, it renders a `button` element.
		 */
		const Comp = asChild ? Slot : "button";

		return (
			<Comp
				type="button"
				/**
				 * The class name of the button component.
				 */
				className={cn({
					inputs: [
						buttonVariants({ variant, size, align }),
						props.disabled && "opacity-50 cursor-not-allowed",
						className,
					],
				})}
				/**
				 * The ref of the button component.
				 */
				ref={ref}
				/**
				 * The remaining props of the button component.
				 */
				{...props}
			/>
		);
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
