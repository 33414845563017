import type { ApiGetNccTeamGetNccteamRiderListId } from "@/routes/webpanel/drivers/_types";
import {
	type QueryKey,
	type QueryObserverOptions,
	type UseQueryResult,
	queryOptions,
	useQuery,
} from "@tanstack/react-query";
import axiosClient from "api/templatesSDK/client";

export function getApiNccTeamGetNccteamRiderListIdQueryOptions(
	nccTeamId: number,
	queryKey: QueryKey = ["/api/transport/ncc-team/rider/list/:nccTeam_id"],
	options: ApiGetNccTeamGetNccteamRiderListId["client"]["parameters"] = {},
) {
	return queryOptions({
		queryKey,
		queryFn: async () => {
			const res = await axiosClient({
				method: "get",
				url: `/api/transport/ncc-team/rider/list/${nccTeamId}`,
			});
			return res.data;
		},
		...options,
		enabled: !!nccTeamId,
	});
}

const apiGetNccTeamGetNccteamRiderListIdQueryKey = () => ["/api/transport/ncc-team/rider/list/:nccTeam_id"] as const;
type ApiGetNccTeamGetNccteamRiderListIdQueryKey = ReturnType<typeof apiGetNccTeamGetNccteamRiderListIdQueryKey>;
export function useApiGetNccTeamGetNccteamRiderListIdHook<
	TData = ApiGetNccTeamGetNccteamRiderListId["response"],
	TQueryData = ApiGetNccTeamGetNccteamRiderListId["response"],
	TQueryKey extends QueryKey = ApiGetNccTeamGetNccteamRiderListIdQueryKey,
>(
	ncc_team_id: ApiGetNccTeamGetNccteamRiderListId["pathParams"]["ncc_team_id"],
	options: {
		query?: Partial<
			QueryObserverOptions<
				ApiGetNccTeamGetNccteamRiderListId["response"],
				ApiGetNccTeamGetNccteamRiderListId["error"],
				TData,
				TQueryData,
				TQueryKey
			>
		>;
		client?: ApiGetNccTeamGetNccteamRiderListId["client"]["parameters"];
	} = {},
): UseQueryResult<TData, ApiGetNccTeamGetNccteamRiderListId["error"]> & {
	queryKey: TQueryKey;
} {
	const { query: queryOptions, client: clientOptions = {} } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? apiGetNccTeamGetNccteamRiderListIdQueryKey();
	const query = useQuery({
		...getApiNccTeamGetNccteamRiderListIdQueryOptions(ncc_team_id, queryKey),
		enabled: !!ncc_team_id,
		queryKey,
		...clientOptions,
		...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
	}) as UseQueryResult<TData, ApiGetNccTeamGetNccteamRiderListId["error"]> & {
		queryKey: TQueryKey;
	};
	query.queryKey = queryKey as TQueryKey;
	return query;
}
