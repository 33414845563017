import { Button } from "@/components/ui/button";
import Dropdown from "@/components/ui/dropdown";
import type { SelectOption, SelectProps } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { GoChevronDown } from "react-icons/go";

/**
 * Represents an option in the select dropdown.
 */
export type OnOffSelectOption<OptionType> = SelectOption<OptionType> & {
	status?: "on" | "off";
};

/**
 * Represents the props for the Select component.
 */
type OnOffSelectProps<OptionType> = SelectProps<OptionType> & {
	options: OnOffSelectOption<OptionType>[];
};

/**
 * A custom select dropdown component.
 * @param {SelectProps} props - The props for the Select component.
 * @returns {JSX.Element} The rendered Select component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  const [selected, setSelected] = useState("");
 *
 *  return (
 *       <OnOffSelect
 *         title="Seleziona uno stato"
 *         name="opzione"
 * size="sm"
 * options={[
 * 	{ value: "attivo", label: "Attivo", status: "on" },
 * 	{ value: "disattivo", label: "Disattivo", status: "off" },
 * ]}
 * value={selected ? "attivo" : "disattivo"}
 * 		/>
 *  );
 */
const OnOffSelect = <OptionType,>({
	title,
	name,
	value,
	options,
	size,
	disabled,
	className,
	placement,
	onChange,
}: OnOffSelectProps<OptionType>): JSX.Element => {
	const [selected, setSelected] = useState<OnOffSelectOption<OptionType>>();

	useEffect(() => {
		if (value) setSelected(options.find(ob => ob.value === value));
	}, [value]);

	const handleSelect = (option: OnOffSelectOption<OptionType>) => {
		onChange(option?.value, name);
		setSelected(option);
	};

	return (
		<Dropdown
			className={cn({ inputs: ["bg-foreground text-background-base px-0 py-1", className] })}
			placement={placement}
			trigger={
				<Button
					size={size}
					type="button"
					disabled={disabled}
					className={cn({ inputs: ["w-full flex justify-between", className] })}
					variant={selected?.status === "on" ? "default" : "black"}
				>
					<span className="truncate">{selected?.label ?? title}</span>
					<GoChevronDown className="mt-1" />
				</Button>
			}
		>
			{options
				.filter(option => option.value !== selected?.value)
				.map(option => (
					<Button
						disabled={option.disabled?.(value)}
						onClick={() => handleSelect(option)}
						size={size}
						variant={"transparent"}
						align={"left"}
						key={option.label}
						className={cn({ inputs: [className, "text-accent"] })}
					>
						{option.label}
					</Button>
				))}
		</Dropdown>
	);
};

export { OnOffSelect };
