import { UserAuthForm } from "@/components/auth/UserAuthForm";
import { Link, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/signIn")({
	component: () => SignIn(),
	beforeLoad(opts) {
		if (opts.context.auth.token) {
			return { redirect: "/webpanel/veichles" };
		}
	},
});

export default function SignIn() {
	return (
		<>
			<div className="container h-screen flex flex-row items-center justify-center max-w-full bg-background-content">
				<div className="mx-auto w-80 flex flex-col items-center gap-6 rounded-container shadow-2xl p-8 pt-16 bg-background-base">
					<div className="text-center">
						<h1 className="text-2xl font-semibold tracking-tight ">Accedi</h1>
					</div>
					<UserAuthForm />
					<Link
						to="/forgotPassword"
						className="mt-1 text-sm text-foreground-muted text-center"
					>
						Password dimenticata?
					</Link>
				</div>
			</div>
		</>
	);
}
