import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { IoIosCloseCircleOutline as CloseCircleOutline } from "react-icons/io";

export function DeleteDialog({ onSubmit }: { onSubmit: () => void }) {
	const [isDialogOpenDeleteRateType, setIsDialogOpenDeleteRateType] = useState(false);

	return (
		<>
			<Button
				onClick={() => setIsDialogOpenDeleteRateType(true)}
				variant={"ghost"}
				className="px-4 py-2 rounded-md"
			>
				<CloseCircleOutline />
			</Button>
			<Dialog
				isOpen={isDialogOpenDeleteRateType}
				onClose={() => setIsDialogOpenDeleteRateType(false)}
				title="Elimina tariffa"
			>
				<div className="flex flex-col space-y-20 items-center justify-center ">
					<Label>Sei sicuro di volere eliminare questa tariffa?</Label>
					<div className="flex  space-x-2">
						<Button
							type="submit"
							size={"xl"}
							onClick={() => {
								onSubmit();
								setIsDialogOpenDeleteRateType(false);
							}}
						>
							Elimina
						</Button>
						<Button
							type="button"
							size={"xl"}
							variant={"outline"}
							onClick={() => setIsDialogOpenDeleteRateType(false)}
						>
							Annulla
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
