import Page from "@/components/navigation/page";
import { Button } from "@/components/ui/button";
import { Input, inputVariants } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import Switch from "@/components/ui/switch";
import TabBar, { type Tab } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";
import { queryClient } from "@/main";
import { ErrorRoutingComponent } from "@/root";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import {
	getApiTransportTierListNccteamIdQueryOptions,
	getApiUserMeQueryOptions,
	useGetApiTransportTierListNccteamIdHookSuspense,
	usePostApiTransportTierEditHook,
} from "api/gen";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import toast from "react-hot-toast";
import { AddVeichle } from "./_AddRideTierDialog";
import { DistanceTable } from "./_DistanceTable";
import { RateTypeTable } from "./_RateTypeTable";
import { type PagedRideTiers, RateSearchSchema, type RideTier } from "./_types";
import { useGetInfiniteScrollAdditionalFee } from "./_useGetInfiniteScrollAdditionalFee";
import { useGetInfiniteScrollDistancePricing } from "./_useGetInfiniteScrollDistancePricing";
import { useGetInfiniteScrollTransportTierListNccteamId } from "./_useGetInfiniteScrollTransportTierListNccteamId";

export const Route = createFileRoute("/webpanel/rates/")({
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	validateSearch: search => RateSearchSchema.parse(search),
	loaderDeps: ({ search: { page } }) => ({ page }),
	loader: ({ context: { queryClient }, deps: { page } }) => {
		queryClient
			.ensureQueryData(getApiUserMeQueryOptions())
			.then(getMeData =>
				queryClient.ensureQueryData(
					getApiTransportTierListNccteamIdQueryOptions(
						(getMeData as GetMeData).data.nccteams[0]?.id as number,
						undefined,
						{ params: { page } },
					),
				),
			);
	},
	component: ManagementRates,
});

function ManagementRates() {
	const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeCache = !loadingUserData ? (data as GetMeData) : undefined;

	const {
		data: rideTiersVehicleQuery,
		isPending: rideTiersPending,
		fetchNextPage: fetchNextPageRideTiers,
	} = useGetInfiniteScrollTransportTierListNccteamId(getMeCache?.data.nccteams[0]?.id as number, {
		query: { queryKey: ["rideTiers", `/api/transport/tier/list/${getMeCache?.data.nccteams[0]?.id}`] },
	});
	const rideTiers = rideTiersVehicleQuery?.pages.flatMap(page => page.data) as
		| (typeof rideTiersVehicleQuery & RideTier[])
		| undefined;
	const [currentRideTier, setCurrentRideTier] = useState<RideTier>();
	const [currentTab, setCurrentTab] = useState<Tab>();
	const { mutate: transportTierEdit } = usePostApiTransportTierEditHook();
	const [isActiveIndicator, setIsActiveIndicator] = useState(false);
	const [activationFee, setActivationFee] = useState<string | undefined>();

	const {
		data: distancePricingData,
		isPending: pendingDistancePricingData,
		fetchNextPage: fetchNextPageDistancePricing,
	} = useGetInfiniteScrollDistancePricing(currentRideTier?.id as number, {
		query: {
			enabled: !!currentRideTier?.id && !loadingUserData,
			queryKey: ["distancePricing", `distancePricing-${currentRideTier?.id}`],
		},
	});
	const {
		data: additionalFeesData,
		isPending: pendingAdditionalFeesData,
		fetchNextPage: fetchNextPageAdditionalFee,
	} = useGetInfiniteScrollAdditionalFee(currentRideTier?.id as number, {
		query: {
			enabled: !!currentRideTier?.id && !loadingUserData,
			queryKey: ["additionalFee", `additionalFee-${currentRideTier?.id}`],
		},
	});

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(() => {
		fetchNextPageRideTiers();
	}, [fetchNextPageRideTiers, rideTiersPending]);

	//a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
	useEffect(() => {
		fetchMoreOnBottomReached();
	}, [fetchMoreOnBottomReached]);

	const tabs = useMemo<Tab[]>(
		() =>
			!rideTiersPending && rideTiers && getMeCache
				? rideTiers.map(rideTier => ({
						name: rideTier.name,
						value: rideTier.id,
						hook: (
							<div
								className={cn({
									inputs: [
										"text-foreground-muted",
										rideTier.public && "text-foreground",
										rideTier.id === currentRideTier?.id && "text-accent-muted",
										rideTier.id === currentRideTier?.id && rideTier.public && "text-accent",
									],
								})}
							>
								{rideTier.name}
							</div>
						),
					}))
				: [],
		[rideTiers, getMeCache, currentRideTier],
	);

	useEffect(() => {
		if (rideTiers) {
			let updateRideTier: RideTier | undefined;
			if (currentRideTier) updateRideTier = rideTiers.find(rideTier => rideTier.id === currentRideTier.id);
			if (!updateRideTier) updateRideTier = rideTiers[0];
			setCurrentRideTier(updateRideTier);
			setIsActiveIndicator(!!updateRideTier?.public);
		}
	}, [rideTiers]);

	const onTabChange = (tab: Tab) => {
		if (rideTiers) {
			const rideTier = rideTiers.find(rideTier => rideTier.id === tab.value);
			setActivationFee(rideTier?.activation_fee);
			setCurrentRideTier(rideTier);
			setIsActiveIndicator(!!rideTier?.public);
		}
		setCurrentTab(tab);
	};

	const setRideTierIsActive = (active: boolean) => {
		if (currentRideTier) {
			const { max_seats: _, ...newObj } = currentRideTier;
			transportTierEdit(
				{
					...newObj,
					ncc_team_id: getMeCache?.data.nccteams[0]?.id as number,
					activation_fee: activationFee ? Number.parseFloat(activationFee.replace(",", ".")).toString() : "0",
					vehicle_type_id: currentRideTier.vehicle_type.id,
					public: !isActiveIndicator,
				},
				{
					onError(error) {
						if (error instanceof AxiosError && error.response?.data?.message) {
							setIsActiveIndicator(!active);
						}
					},
				},
			);
			setTimeout(() => queryClient.invalidateQueries({ queryKey: ["rideTiers"] }), 100);
			setIsActiveIndicator(active);
		}
	};

	return (
		<Page>
			{!rideTiersPending && tabs && tabs.length > 0 ? (
				<>
					<div className="flex flex-row justify-between w-full mb-4">
						<div className="flex gap-4 overflow-hidden">
							<TabBar
								currentTab={currentTab}
								setCurrentTab={onTabChange}
								renderTabContent={false}
								className="h-12"
								tabs={tabs}
							/>
							<AddVeichle className="mt-3" />

							{currentRideTier ? (
								<div className="flex flex-col gap-2 items-end">
									<Switch
										checked={isActiveIndicator}
										defaultChecked={false}
										onChange={setRideTierIsActive}
									/>
									<Label className={cn({ inputs: ["text-center w-14", isActiveIndicator && "text-accent"] })}>
										{isActiveIndicator ? "Tariffa attiva" : "Tariffa inattiva"}
									</Label>
								</div>
							) : (
								<div className="flex flex-col gap-2 items-end">
									<Skeleton className="w-12 h-6" />
								</div>
							)}
						</div>
					</div>

					<div className="flex gap-8">
						<RateTypeTable
							fetchNextPage={fetchNextPageDistancePricing}
							rideTierId={currentRideTier?.id}
							veichleRideId={currentRideTier?.vehicle_type.id}
							nccTeamId={getMeCache?.data.nccteams[0]?.id as number}
							dataRateTypeTable={distancePricingData}
							rideTiersPending={pendingDistancePricingData}
						/>

						<div className="flex flex-col gap-4">
							<Label>Costo di attivazione</Label>
							<div className="flex items-center gap-2">
								{currentRideTier ? (
									<CurrencyInput
										customInput={Input}
										className={cn({ inputs: ["max-w-14 h-8", inputVariants({ variant: "ghost" })] })}
										placeholder="0.00"
										defaultValue={currentRideTier.activation_fee}
										value={activationFee}
										onValueChange={value => {
											setActivationFee(value);
										}}
										decimalsLimit={2}
									/>
								) : (
									<Skeleton className="w-12 h-6" />
								)}
								€
								<Button
									disabled={!currentRideTier}
									onClick={() => {
										currentRideTier && activationFee
											? transportTierEdit(
													{
														...currentRideTier,
														ncc_team_id: getMeCache?.data.nccteams[0]?.id as number,
														activation_fee: activationFee.replace(",", "."),
														vehicle_type_id: currentRideTier.vehicle_type.id,
														public: !!currentRideTier.public, // Convert the 'public' property to a boolean value
													},
													{
														onSuccess: () => {
															queryClient.invalidateQueries({
																queryKey: ["rideTiers", `/api/transport/tier/list/${getMeCache?.data.nccteams[0]?.id}`],
															});
															toast.success("Costo di attivazione modificato con successo");
														},
														onError: () => toast.error("Errore durante la modifica del costo di attivazione"),
													},
												)
											: null;
									}}
								>
									Conferma
								</Button>
							</div>

							<DistanceTable
								fetchNextPage={fetchNextPageAdditionalFee}
								dataDistanceTable={additionalFeesData}
								rideTiersPending={pendingAdditionalFeesData}
							/>
						</div>
					</div>
				</>
			) : null}
		</Page>
	);
}
