import { Button } from "@/components/ui/button";
import { Input, inputVariants } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useRideIdStore, useVeichleRideTypeIdStore } from "@/hooks/store/main";
import { cn } from "@/lib/utils";
import { queryClient } from "@/main";
import type { InfiniteData } from "@tanstack/react-query";
import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { usePostApiTransportTierPricingCreateHook } from "api/gen";
import { type ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CurrencyInput, { type CurrencyInputProps } from "react-currency-input-field";
import toast from "react-hot-toast";
import { GoPlus } from "react-icons/go";
import { columnsRates } from "./_columns";
import type { IRateTypeTable, PagedRideTiers, RideTier } from "./_types";

export function RateTypeTable<T extends InfiniteData<PagedRideTiers, unknown> | undefined>({
	dataRateTypeTable,
	rideTiersPending,
	nccTeamId,
	rideTierId,
	veichleRideId,
	fetchNextPage,
}: IRateTypeTable<T>) {
	//we need a reference to the scrolling element for logic down below
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const [newFeeDistance, setNewFeeDistance] = useState<number>();
	const [newFeePrice, setNewFeePrice] = useState<string | undefined>();
	const { setRideId } = useRideIdStore();
	const { mutate: tierPricingCreate } = usePostApiTransportTierPricingCreateHook(undefined, {
		mutation: { mutationKey: [{ rideTier_id: rideTierId }] },
	});
	const { setVeichleRideTypeId: setVehicleRideTypeId } = useVeichleRideTypeIdStore();

	useEffect(() => {
		if (!rideTiersPending) {
			setRideId(rideTierId);
			setVehicleRideTypeId(veichleRideId);
		}
	}, [rideTierId]);

	const tableColumns = useMemo<ColumnDef<RideTier>[]>(
		() =>
			rideTiersPending
				? columnsRates.map(column => ({ ...column, cell: () => <Skeleton className="h-2 w-full" /> }))
				: columnsRates,
		[rideTiersPending, columnsRates],
	);

	//flatten the array of arrays from the useInfiniteQuery hook
	const tableData = useMemo<RideTier[]>(
		() => (rideTiersPending ? Array(30).fill({}) : dataRateTypeTable?.pages.flatMap(page => page.data)) ?? [],
		[dataRateTypeTable?.pages, rideTiersPending],
	);
	const totalRowCount = dataRateTypeTable?.pages?.[0]?.meta.total ?? 0;
	const totalFetched = tableData.length;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 500 && !rideTiersPending && totalFetched < totalRowCount) {
					fetchNextPage();
				}
			}
		},
		[fetchNextPage, rideTiersPending, totalFetched, totalRowCount],
	);

	//a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
	useEffect(() => {
		fetchMoreOnBottomReached(tableContainerRef.current);
	}, [fetchMoreOnBottomReached]);

	const table = useReactTable({
		data: tableData,
		columns: tableColumns,
		getCoreRowModel: getCoreRowModel(),
	});

	const { rows } = table.getRowModel();

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5,
	});

	const handleNewFeePriceChange: CurrencyInputProps["onValueChange"] = value => setNewFeePrice(value);

	return (
		<div className="flex flex-col gap-y-6 items-center justify-start max-w-max">
			<div className="flex flex-col gap-4">
				<Label>Aggiungi nuova tariffa</Label>
				<div className="flex gap-2 items-center">
					<Input
						type="number"
						variant={"ghost"}
						placeholder="0"
						className="max-w-16 h-8"
						value={newFeeDistance}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							setNewFeeDistance(Number.parseFloat(e.target.value));
						}}
					/>
					Km
					<CurrencyInput
						customInput={Input}
						placeholder="0.00"
						className={cn({ inputs: ["max-w-14 h-8", inputVariants({ variant: "ghost" })] })}
						value={newFeePrice}
						onValueChange={handleNewFeePriceChange}
						decimalsLimit={2}
					/>
					€
					<Button
						onClick={() => {
							if (newFeePrice && rideTierId)
								tierPricingCreate(
									{
										km_price: newFeePrice.replace(",", "."),
										max_distance: newFeeDistance,
										ride_tier_id: rideTierId,
										km_price_reason: `Prezzo fascia per ${newFeeDistance}km`,
										ncc_team_id: nccTeamId,
									},
									{
										onSuccess: () => {
											toast.success("Tariffa creata con successo");
											queryClient.invalidateQueries({ queryKey: ["distancePricing"] });
										},
										onError: () => toast.error("Errore durante la creazione della tariffa"),
									},
								);
						}}
					>
						<GoPlus className="w-4 h-4" /> Aggiungi
					</Button>
				</div>
			</div>
			<div
				className="h-[300px] overflow-auto relative"
				onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
				ref={tableContainerRef}
			>
				<Table className="bg-black">
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody className={cn({ inputs: [`height-[${rowVirtualizer.getTotalSize()}px]`] })}>
						{rowVirtualizer.getVirtualItems().map(virtualRow => {
							const row = rows[virtualRow.index];
							if (row) {
								return (
									<TableRow
										data-index={virtualRow.index} //needed for dynamic row height measurement
										ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
										key={row.id}
									>
										{row.getVisibleCells().map(cell => (
											<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
										))}
									</TableRow>
								);
							}
							<TableRow>
								<TableCell
									colSpan={columnsRates.length}
									className="h-24 text-center"
								>
									Nessun risultato trovato
								</TableCell>
							</TableRow>;
						})}
					</TableBody>
				</Table>
			</div>
		</div>
	);
}
