import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import axiosClient from "api/templatesSDK/client";

/**
 * Custom hook for setting the locale language.
 * @returns {UseMutationResult} The mutation object.
 */
export function useSetLocaleLanguageHook() {
	const mutationUrl = "/api/user/update-locale";

	return useMutation({
		mutationFn: async () => {
			const res = await axiosClient({
				method: "post",
				url: mutationUrl,
				data: {
					locale: "it",
				},
			});
			return res.data;
		},
		mutationKey: [mutationUrl],
	});
}
