import type { IPagination } from "@/types";
import type axiosClient from "api/templatesSDK/client";
import { z } from "zod";

export const driverSearchSchema = z.object({
	page: z.number().catch(1),
});

export interface ResDrivers extends IPagination {
	data: Drivers[];
}

export interface Drivers {
	id: number;
	status: string;
	active: boolean;
	available: boolean;
	user: User;
}

export interface User {
	id: number;
	name: string;
	gender: string;
	locale: string;
	dob: string;
	phone_country: string;
	phone: string;
	email: string;
}

interface PaginatedDrivers extends IPagination {
	data: Drivers[];
	status?: string;
	message?: string;
}

type ApiGetNccTeamGetNccteamRiderListIdClient = typeof axiosClient<
	PaginatedDrivers,
	{
		status?: string;
		message?: string;
	},
	never
>;

export type ApiGetNccTeamGetNccteamRiderListId = {
	data: PaginatedDrivers;
	error: {
		status?: string;
		message?: string;
	};
	request: never;
	pathParams: {
		ncc_team_id: number;
	};
	queryParams: never;
	response: PaginatedDrivers;
	client: {
		parameters: Partial<Parameters<ApiGetNccTeamGetNccteamRiderListIdClient>[0]>;
		return: Awaited<ReturnType<ApiGetNccTeamGetNccteamRiderListIdClient>>;
	};
};
