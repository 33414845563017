import { Input } from "@/components/ui/input";
import type { OnOffSelectOption } from "@/components/ui/onOffSelect";
import { Select } from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import type { Rides } from "@/routes/webpanel/rides/_types";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { Table } from "@tanstack/react-table";
import { getApiUserMeQueryOptions, usePostApiNccTeamStatusChangeHook } from "api/gen";
import toast from "react-hot-toast";

export const RidesUtils = ({
	table,
	currentState,
	setCurrentState,
}: {
	table: Table<Rides>;
	currentState: "Offline" | "Live" | "Online" | undefined;
	setCurrentState: (state: "Offline" | "Online" | "Live" | undefined) => void;
}) => {
	const { mutate: nccTeamStatusChange } = usePostApiNccTeamStatusChangeHook();

	const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeData = data as GetMeData | undefined;

	const driverStatus: OnOffSelectOption<"Offline" | "Online" | "Live">[] = [
		{ value: "Offline", label: "Offline", status: "off" },
		{
			value: "Online",
			label: "Corse programmate",
			status: "on",
			disabled: currentValue => currentValue === "Online",
		},
		{
			value: "Live",
			label: "Corse immediate",
			status: "on",
			disabled: currentValue => currentValue !== "Online" && currentValue !== "Live",
		},
	];

	return (
		<div className="flex shrink-0 justify-between gap-3 overflow-hidden items-center p-3 bg-background-content w-full mb-3 rounded-container">
			<Input
				placeholder="Cerca corsa..."
				value={table.getColumn("id")?.getFilterValue() as string}
				onChange={event => table.getColumn("id")?.setFilterValue(event.target.value)}
				variant={"outline"}
				className="w-48"
			/>
			{!loadingUserData ? (
				<div>
					<Select
						name="status"
						title="Stato"
						placement={"bottom-end"}
						value={currentState}
						onChange={state => {
							setCurrentState(state);

							nccTeamStatusChange(
								{
									ncc_team_id: (getMeData as GetMeData)?.data.nccteams[0]?.id as number,
									status: state,
								},
								{
									onSuccess() {
										toast.success("Stato modificato con successo");
									},
									onError() {
										toast.error("Errore durante la modifica dello stato");
									},
								},
							);
						}}
						options={driverStatus}
					/>
				</div>
			) : (
				<Skeleton className="w-48" />
			)}
		</div>
	);
};
