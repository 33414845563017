import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import type { Table } from "@tanstack/react-table";
import { GarargeUtilsForm } from "./_GarageUtilsForm";

import { useState } from "react";
import { FaPlus as Plus } from "react-icons/fa";
import type { Garages } from "./_types";

export const GarageUtils = ({ table }: { table: Table<Garages> }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const openDialog = () => setIsDialogOpen(true);
	const closeDialog = () => setIsDialogOpen(false);

	return (
		<div className="flex shrink-0 justify-between gap-3 overflow-hidden items-center p-3 bg-background-content w-full mb-3 rounded-container">
			<Input
				placeholder="Cerca autorimessa..."
				value={table.getColumn("name")?.getFilterValue() as string}
				onChange={event => table.getColumn("name")?.setFilterValue(event.target.value)}
				variant={"outline"}
				className="w-48"
			/>
			<div>
				<Button
					onClick={openDialog}
					className="px-4"
				>
					<Plus className="w-2" /> Crea nuova autorimessa
				</Button>
				<Dialog
					isOpen={isDialogOpen}
					onClose={closeDialog}
					title="Crea nuova autorimessa"
				>
					<GarargeUtilsForm closeDialog={closeDialog} />
				</Dialog>
			</div>
		</div>
	);
};
