import { OnOffSelect } from "@/components/ui/onOffSelect";
import type { ColumnDef } from "@tanstack/react-table";
import { usePostApiNccTeamNccLocationEditHook } from "api/gen";
import toast from "react-hot-toast";
import type { Garages } from "./_types";

export const columnsGarage: ColumnDef<Garages>[] = [
	{
		accessorKey: "name",
		header: "Nome",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("name")}</div>,
	},
	{
		accessorKey: "address",
		header: "Luogo",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("address")}</div>,
	},
	{
		accessorKey: "active",
		header: "Status",
		cell: ({ row }) => {
			const status: boolean = row.getValue("active");
			const { mutate: ChangeGarageStatus } = usePostApiNccTeamNccLocationEditHook();

			return (
				<div className="w-32 -my-2">
					<OnOffSelect
						title="Seleziona uno status"
						name="stato"
						size="sm"
						onChange={value =>
							ChangeGarageStatus(
								{ ...row.original, available: value === "attivo" },
								{
									onSuccess() {
										toast.success("Stato modificato con successo");
									},
									onError() {
										toast.error("Errore durante la modifica dello stato");
									},
								},
							)
						}
						options={[
							{ value: "attivo", label: "Attivo", status: "on" },
							{ value: "disattivo", label: "Disattivo", status: "off" },
						]}
						value={status ? "attivo" : "disattivo"}
					/>
				</div>
			);
		},
	},
];
